import { yupResolver } from '@hookform/resolvers/yup';
import { RegistrationStepOneProps, RegistrationStepTwoProps } from '@type/auth';
import {
  registrationStepOneSchema,
  registrationStepTwoSchema,
} from 'containers/Account/validationSchemas';
import { useAuthStore } from 'contexts/AuthContext';
import { useCommonStore } from 'contexts/CommonContext';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const useRegistrationForm = (
  step: number,
  setStep: (val: number) => void,
  setSendEmail: (val: string) => void,
  setSuccessData: (val: object) => void
) => {
  const { t, i18n } = useTranslation();
  const commonStore = useCommonStore();
  const authStore = useAuthStore();
  const currentLanguage = localStorage.getItem('kitGlobalLng');
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [schema, setSchema] = useState<any>(registrationStepOneSchema(t));
  const [password, setPassword] = useState<string>('');
  const [recaptcha, setRecaptcha] = useState('');
  const [passwordErrorDescription, setPasswordErrorDescription] =
    useState<string>('');
  const [selectedProducts, setSelectedProducts] = useState<
    {
      id: number;
      name: React.ReactNode;
    }[]
  >([]);
  const [selectedLocation, setSelectedLocation] = useState<{
    id: number;
    name: string;
  }>();
  const [isErrorStepOne, setIsErrorStepOne] = useState(false);
  const [checkErrors, setCheckErrors] = useState(false);
  const [productsPage, setProductsPage] = useState(1);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
  } = useForm<RegistrationStepOneProps | RegistrationStepTwoProps>({
    resolver: yupResolver(schema as any),
    mode: 'all',
  });

  useEffect(() => {
    setSchema(
      step === 1 ? registrationStepOneSchema(t) : registrationStepTwoSchema(t)
    );
  }, [step, currentLanguage]);

  const { fullName, email, phone, companyName, companyUrl, jobTitle } =
    getValues() as RegistrationStepOneProps & RegistrationStepTwoProps;

  const setErrorMessage = (field: any, message: string) => {
    setError(field, { type: 'server', message });
  };

  const onLoadProducts = () => {
    if (commonStore.loadProductsStatus.data?.next) {
      setProductsPage((page) => page + 1);
    }
  };

  const onSubmit = async () => {
    const formData = new FormData();

    setCheckErrors(isValid);

    formData.append('full_name', fullName);
    formData.append('email', email);
    formData.append('phone_number', '+' + phone);
    formData.append('password', password);
    formData.append('g-recaptcha-response', recaptcha);
    formData.append('source_page', window.location?.href);
    formData.append('language', i18n.language);
    formData.append('company_name', companyName);
    formData.append('company_link', companyUrl);
    formData.append('user_role_in_company', jobTitle);
    formData.append(
      'user_products',
      selectedProducts.map((product) => product?.id).toString()
    );
    formData.append('country', String(selectedLocation?.id));

    authStore.register(
      formData,
      (response) => {
        setStep(3);
        setSendEmail(email);
        setSuccessData(response.data);
      },
      (response) => {
        recaptchaRef?.current?.reset();
        const { form_errors } = response;

        const {
          full_name,
          email,
          phone_number,
          company_name,
          company_link,
          password,
          user_products,
          user_role_in_company,
          country,
        } = form_errors;

        if (full_name) {
          setErrorMessage('fullName', full_name[0]);
        }

        if (email) {
          setErrorMessage('email', email[0]);
        }

        if (phone_number) {
          setErrorMessage('phone', phone_number[0]);
        }

        if (company_name) {
          setErrorMessage('companyName', company_name[0]);
        }

        if (company_link) {
          setErrorMessage('companyUrl', company_link[0]);
        }

        if (user_role_in_company) {
          setErrorMessage('jobTitle', user_role_in_company[0]);
        }

        if (user_products) {
          setErrorMessage('userProducts', user_products[0]);
        }

        if (country) {
          setErrorMessage('location', country[0]);
        }

        if (password) {
          setErrorMessage('password', password[0]);
          setPasswordErrorDescription(password[0]);
        }

        if (full_name || email || phone_number || password) {
          setStep(1);
        }
      }
    );
  };

  useEffect(() => {
    setIsErrorStepOne(!!errors?.email);
  }, [
    errors?.fullName,
    errors?.email,
    errors?.phone,
    errors?.password,
    errors?.iAgree,
  ]);

  useEffect(() => {
    if (checkErrors) {
      if (isErrorStepOne) {
        setStep(1);
      }
    }
  }, [checkErrors, setStep, isErrorStepOne]);

  useEffect(() => {
    commonStore.loadCountries({ all: true }); //добавить пагинацию при необходимости, на данный момент весь список стран приходит за ~188ms, а одна страница ~ за 90ms(поэтому смысла в пагинации нет)
  }, []);

  useEffect(() => {
    commonStore.loadCompanyProducts({ page: productsPage });
  }, [productsPage]);

  return {
    handleSubmit,
    onSubmit,
    control,
    errors,
    password,
    setPassword,
    isValid,
    recaptchaRef,
    setRecaptcha,
    recaptcha,
    selectedProducts,
    setSelectedProducts,
    commonStore,
    onLoadProducts,
    selectedLocation,
    setSelectedLocation,
  };
};
