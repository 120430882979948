import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import {
  Route,
  Navigate,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  defer,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Registration } from './pages/Registration';
import { Login } from 'pages/Login';
import { NotFoundPage } from 'pages/NotFoundPage';
import { routers } from 'constants/routers';
import { Restore } from 'pages/Restore';
import { NewPassword } from 'pages/NewPassword';
import { Account } from 'pages/Account';
import { Projects } from 'pages/Projects';
import { Finances } from 'pages/Finances';
import { SearchResults } from 'pages/SearchResults';
import { Disclaimer } from 'pages/Disclaimer';
import { authStore } from './stores/authStore';
import { AuthRoute } from './components/common/AuthRoute';
import { ProtectRoute } from './components/common/ProtectRoute';
import { ConfirmEmail } from './components/common/ConfirmEmail';
import { BaseTemplate } from './components/common/BaseTemplate';
import { RedirectAuth } from './components/common/RedirectAuth';
import { LoaderWrapper } from 'components/common/LoaderWrapper';
import Hotjar from '@hotjar/browser';
import useCookieConsent from 'hooks/useCookieConsent';
import * as Sentry from '@sentry/react';
import { ProjectsDetail } from 'pages/ProjectsDetail';
import { requestData } from './utils';
import { userStore } from './stores/user-store';
import { PartnerPage } from './pages/Partner';
import { RUSSIAN_LOCALE } from './utils';
import { AddSolutionPage } from './pages/AddSolution';
import { SolutionDetail } from 'pages/SolutionDetail';
import { ErrorPage } from './pages/ErrorPage';
import ConfirmModerate from 'pages/ConfirmModerate';
import { PermissionErrorPage } from 'pages/PermissionErrorPage';
import { LoginFromKID } from 'pages/LoginFromKID';

const RoutesJSX = (
  <Route
    path=""
    element={<AuthRoute />}
    loader={async () => {
      const isAccessToken = authStore.accessToken;
      return defer(
        isAccessToken
          ? {}
          : {
              refresh: await authStore.refreshAuth(),
            }
      );
    }}
  >
    <Route element={<RedirectAuth />}>
      <Route path="/register" element={<Registration />} />
      <Route path="/login" element={<Login />} />
      <Route path="/restore" element={<Restore />} />
      <Route path="/new-password" element={<NewPassword />} />
      <Route path="/kid" element={<LoginFromKID />} />
    </Route>
    <Route element={<ProtectRoute />}>
      <Route
        element={<BaseTemplate />}
        loader={async () => {
          const { isServerError } = authStore || {};
          const options = {
            user: !isServerError,
            types: !isServerError,
          };
          return defer({
            loader: requestData(options),
          });
        }}
      >
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/error" element={<ErrorPage />} />

        <Route element={<PermissionErrorPage />}>
          <Route element={<ConfirmEmail />}>
            <Route element={<ConfirmModerate />}>
              <Route path="/" element={<Navigate to="/solutions" />} />
              <Route
                path="/solutions"
                element={
                  <LoaderWrapper>
                    <PartnerPage />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    types: true,
                    partnerServices: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/solutions/:id"
                element={
                  <LoaderWrapper>
                    <SolutionDetail />
                  </LoaderWrapper>
                }
                loader={async ({ params }) => {
                  const options = {
                    serviceItem: true,
                    user: true,
                    increment: true,
                    types: true,
                    products: true,
                    platforms: true,
                    kpiOptions: true,
                    dictionaries: true,
                  };
                  return defer({
                    loader: requestData(options, params),
                  });
                }}
              />
              <Route
                path="/account/"
                element={
                  <LoaderWrapper>
                    <Account />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    auth: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/account/personal"
                element={
                  <LoaderWrapper>
                    <Account section={'personal'} />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    auth: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/account/business"
                element={
                  <LoaderWrapper>
                    <Account section={'business'} />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    auth: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/account/change-password"
                element={
                  <LoaderWrapper>
                    <Account
                      section={RUSSIAN_LOCALE ? 'personal' : 'change-password'}
                    />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    auth: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/projects/"
                element={
                  <LoaderWrapper>
                    <Projects />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    auth: true,
                    projects: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/projects/:id"
                element={
                  <LoaderWrapper>
                    <ProjectsDetail />
                  </LoaderWrapper>
                }
                loader={({ params }) => {
                  const options = {
                    user: true,
                    auth: true,
                    projectInfo: true,
                  };
                  return defer({
                    loader: requestData(options, params),
                  });
                }}
              />
              <Route
                path="/finances/"
                element={
                  <LoaderWrapper>
                    <Finances />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    auth: true,
                    user: true,
                    invoices: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route
                path="/solutions/add-solution/"
                element={
                  <LoaderWrapper>
                    <AddSolutionPage />
                  </LoaderWrapper>
                }
                loader={async () => {
                  const options = {
                    user: true,
                    types: true,
                    products: true,
                    platforms: true,
                    countries: true,
                    kpiOptions: true,
                    dictionaries: true,
                  };
                  return defer({
                    loader: requestData(options),
                  });
                }}
              />
              <Route path={routers.disclaimer} element={<Disclaimer />} />
              <Route path={routers.searchResults} element={<SearchResults />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
  // </Route>
);

let router = '';
const routes = createRoutesFromElements(RoutesJSX);

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY}`,

    tracesSampleRate: 1.0,
  });
  console.log('sentry on');
}
router = createBrowserRouter(routes);

const App = () => {
  const analyticsCookieAccepted = useCookieConsent('platform_analytics');

  if (
    process.env.REACT_APP_SHOW_ANALYTICS === 'true' &&
    analyticsCookieAccepted
  ) {
    const siteId = 3572452;
    const hotjarVersion = 6;

    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });

    Hotjar.init(siteId, hotjarVersion);
    console.log('analytic on');
  } else {
    console.log('analytic off');
  }

  useEffect(() => {
    const shouldStartOnboarding = process.env.REACT_APP_ONBOARDING === 'true';
    if (!shouldStartOnboarding) return;
    localStorage.setItem('onboarding', 'true');
    const onboarding = localStorage.getItem('onboarding');
    onboarding && userStore.enableOnboarding(!!onboarding);
    return () => {
      localStorage.removeItem('onboarding');
    };
  }, []);

  return (
    <>
      <div
        className={RUSSIAN_LOCALE ? 'app__wrap rulocale' : 'app__wrap enlocale'}
      >
        <RouterProvider router={router} />
        <div className="app__toast">
          <ToastContainer
            autoClose={5000}
            draggable={false}
            hideProgressBar={true}
            closeButton={false}
          />
        </div>
      </div>
    </>
  );
};

export default App;
