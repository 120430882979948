import clsx from 'clsx';
import { addSolutionsSecondStepHintsFirst, LABELS } from 'constants/onboarding';
import Modals from 'containers/AddSolution/Modals';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddCaseForm } from '../../AddCaseForm';
import classes from '../FormBlock.module.scss';
import EmptyCase from './EmptyCase/EmptyCase';
import { SecondStepProps } from './SecondStep.props';
import { useSecondStep } from './useSecondStep';
import { useRef } from 'react';

const SecondStep = ({
  setOpenCancelModal,
  disabled,
  setCurrentStep,
  defaultValues,
  platforms,
  currentStep,
  setOpenCasesModal,
  openCasesModal,
}: SecondStepProps) => {
  const { t } = useTranslation();
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const fieldRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const {
    fields,
    enableHints,
    handleExit,
    deleteStep,
    handleExampleLoaded,
    addStep,
    onValidateClick,
  } = useSecondStep(
    disabled,
    setCurrentStep,
    defaultValues,
    setOpenCasesModal,
    openCasesModal
  );
  const { isSmallLaptop: isLaptop } = useWindowWidth();

  return (
    <>
      <div
        className={clsx(
          classes.secondStep,
          fields?.length !== 0 && disabled && classes.disabled
        )}
      >
        <Steps
          enabled={isLaptop && enableHints}
          steps={addSolutionsSecondStepHintsFirst(t)}
          options={{
            tooltipClass: clsx('customTooltip', classes.hintsTooltip),
            showBullets: false,
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={0}
          onComplete={handleExit}
          onExit={handleExit}
        />

        <div>
          {fields?.length
            ? fields.map((field, index) => (
                <Controller
                  key={field.id}
                  render={() => (
                    <AddCaseForm
                      key={field.id}
                      caseNumber={index}
                      onRemoveCase={() => {
                        deleteStep(index);
                      }}
                      platforms={platforms}
                      caseItem={field}
                      disabled={disabled}
                      fieldRefs={fieldRefs}
                      currentStep={currentStep}
                    />
                  )}
                  name={`cases.${index}`}
                />
              ))
            : null}
        </div>

        {!disabled && (
          <EmptyCase
            handleExampleLoaded={handleExampleLoaded}
            disabled={disabled}
            addStep={(e) => {
              addStep(e);
            }}
            setCurrentStep={setCurrentStep}
            setOpenCancelModal={setOpenCancelModal}
            onValidateClick={onValidateClick}
          />
        )}

        {disabled && fields?.length === 0 && (
          <EmptyCase
            handleExampleLoaded={handleExampleLoaded}
            disabled={disabled}
            addStep={addStep}
            setCurrentStep={setCurrentStep}
            setOpenCancelModal={setOpenCancelModal}
            onValidateClick={onValidateClick}
          />
        )}
      </div>

      <Modals
        openCasesModal={openCasesModal}
        setOpenCasesModal={setOpenCasesModal}
        onSubmitCasesModal={addStep}
      />
    </>
  );
};

export default SecondStep;
