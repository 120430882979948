import { Button } from "components/shared/Button";
import UiIcon from "components/shared/Icon";
import { useTranslation } from "react-i18next";
import classes from "../PartnerContainer.module.scss";

const ModerateBlock = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.emptyBlock}>
      <div className={classes.emptyBlock__title}>
        <h1>{t("Account is awaiting verification")}</h1>
      </div>

      <div className={classes.emptyBlock__description}>
        <p>
          {t(
            "You will be able to add your service once your account is verified",
          )}
        </p>
      </div>

      <div className={classes.emptyBlock__button}>
        <Button disabled theme="primary" className={classes.mainButton}>
          <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
          {t("Add solution")}
        </Button>
      </div>
    </div>
  );
};

export default ModerateBlock;
