import { makeAutoObservable } from 'mobx';
import AuthService from 'services/auth';
import { RegisterResponseFail, RegisterResponseSuccess } from '@type/auth';
import api, {
  API_URL,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from 'services/http';
import { userStore } from './user-store';
import axios, { AxiosError } from 'axios';

export class AuthStore {
  isAuth = false;
  isInit = false;
  isLoading = false;
  accessToken = '';
  kid_user_info: {} | null = null;
  isServerError = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(bool: boolean) {
    this.isAuth = bool;
  }

  setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  setInit(bool: boolean) {
    this.isInit = bool;
  }

  setKidUserInfo(user: any) {
    this.kid_user_info = user;
  }

  async loginByTokens(access: string, refresh: string) {
    this.setLoading(true);
    try {
      setAccessToken(access);
      setRefreshToken(refresh);
      return true;
    } catch {
      return false;
    } finally {
      this.setLoading(false);
    }
  }

  async login(email: string, password: string, recaptcha: string) {
    this.setLoading(true);
    try {
      const response = await AuthService.login(email, password, recaptcha);
      if (response.data.status) {
        this.setAuth(true);
        setAccessToken(response.data.data.access);
        setRefreshToken(response.data.data.refresh);
      }
      this.setLoading(false);
      return response.data;
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        return e.response?.data;
      }
    } finally {
      this.setLoading(false);
    }
  }

  async checkToken(token: string) {
    this.setLoading(true);
    try {
      console.log(`${API_URL}/api/v1/auth/kid/check-token/`);
      const response = await api.get(
        `${API_URL}/api/v1/auth/kid/check-token/`,
        { params: { token } }
      );
      if (response.data.status) {
        if (!!response.data.data.access) {
          console.log('token is ok, user is present:', response.data.data);
          this.setAuth(true);
          setAccessToken(response.data.data.access);
          setRefreshToken(response.data.data.refresh);
        } else {
          console.log('token is ok, user:', response.data.data);
          this.setKidUserInfo({ ...response.data.data, token });
        }
      } else {
        console.log('token status is false:', response.data);
      }
      this.setLoading(false);
      return response.data.status ? true : response.data.form_errors.token;
    } catch (e: unknown) {
      this.setKidUserInfo(null);

      if (e instanceof AxiosError) console.log('check_token catch', e.response);
    } finally {
      this.setLoading(false);
    }
  }

  async logout() {
    try {
      // await AuthService.logout()
      setAccessToken('');
      setRefreshToken('');
      this.setServerError(false);
      this.setAuth(false);
    } catch (e: unknown) {
      console.log(e);
    }
  }

  setServerError(isError: boolean) {
    this.isServerError = isError;
  }

  async register(
    data: FormData,
    onSuccess?: (result: RegisterResponseSuccess) => void,
    onErrorData?: (result: RegisterResponseFail) => void,
    onError?: (error: AxiosError) => void
  ) {
    try {
      const result = await AuthService.register(data);
      onSuccess?.(result.data as RegisterResponseSuccess);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error.response) {
          onErrorData?.(error.response.data as RegisterResponseFail);
        }
        onError?.(error);
      }
    }
  }

  async refreshAuth() {
    if (!getRefreshToken()) return;
    this.setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/refresh/`, {
        refresh: getRefreshToken(),
      });
      if (response?.data?.access) {
        setAccessToken(response.data.access);
        this.setAuth(true);
        this.accessToken = response.data.access;
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      if (
        e instanceof AxiosError &&
        ((e.response && e.response?.status >= 500) || e.request?.status >= 500)
      ) {
        this.setAuth(true);
        this.setServerError(true);
        userStore.isComplete = true;
        return e;
      } else {
        return e;
      }
    } finally {
      this.setLoading(false);
      this.setInit(true);
    }
  }

  async verifyAuth() {
    this.setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/verify/`, {
        token: getRefreshToken(),
      });
      this.setAuth(true);
      return true;
    } catch (e: unknown) {
      this.setAuth(false);
      return false;
    } finally {
      this.setLoading(false);
      this.setInit(true);
    }
  }
}

export const authStore = new AuthStore();
