import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './CookieNotice.module.scss';
import { CookieIcon } from 'components/shared/Icons/CookieIcon';
import { Type } from 'components/shared/Type/index';
import { Grid, GridItem } from 'components/common/Grid/index';
import { Link } from 'components/shared/Link';
import { Button } from 'components/shared/Button';
import { useCookies } from 'react-cookie';
import { AcceptCookies } from 'services/api/acceptCookies';
import { DeclineCookies } from 'services/api/declineCookies';
import { ShowNotification } from 'tools/showNotification';
import { defaultLanguage } from 'i18n/i18n';
import { RUSSIAN_LOCALE } from 'utils';
import { useState } from 'react';

export const CookieNotice = () => {
  const { t, i18n } = useTranslation();
  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptCookies = async () => {
    setIsLoading(true);
    try {
      const result = await AcceptCookies({
        cookie_groups_varnames: 'platform_analytics',
        access: cookies.access,
        refresh: cookies.refresh,
      });
      const { name, value, max_age, domain, secure, httponly, samesite } =
        result.cookie_data;
      setCookies(name as 'access' | 'refresh', value, {
        maxAge: max_age,
        domain: domain,
        secure: secure,
        httpOnly: httponly,
        sameSite: samesite,
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclineCookies = async () => {
    setIsLoading(true);
    try {
      const result = await DeclineCookies({
        cookie_groups_varnames: 'platform_analytics',
        access: cookies.access,
        refresh: cookies.refresh,
      });
      const { name, value, max_age, domain, secure, httponly, samesite } =
        result.cookie_data;
      setCookies(name as 'access' | 'refresh', value, {
        maxAge: max_age,
        domain: domain,
        secure: secure,
        httpOnly: httponly,
        sameSite: samesite,
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isRuLocale = i18n.language === 'ru';

  return (
    <div className={clsx(classes['cookie-notice'])}>
      <div className={clsx(classes['cookie-notice__container'])}>
        <div className={clsx(classes['cookie-notice__head'])}>
          <div
            className={clsx(
              classes['cookie-notice__icon'],
              RUSSIAN_LOCALE && classes['cookie-notice__iconRu']
            )}
          >
            <CookieIcon />
          </div>
          <Type tag="h1" size="md" className={classes['cookie-notice__title']}>
            {t('Cookie Notice')}
          </Type>
        </div>
        <Grid direction="row">
          <GridItem col={{ default: 12, sm: 7, md: 8 }}>
            <div className={classes['cookie-notice__text']}>
              {t(
                'We use cookies to analyse and improve your experience with KIT Global website and platform and to provide our services at their best. To learn more about how and for what purposes KIT Global uses personal information, please read our'
              )}{' '}
              <Link
                className={classes['cookie-notice__link']}
                href={`${process.env.REACT_APP_PRIVACY_POLICY_URL}`}
                target="_blank"
              >
                {t('Privacy Policy on cookies notice')}
              </Link>
              {'. '}
              {t('For more information about Cookies please see our')}{' '}
              <Link
                className={classes['cookie-notice__link']}
                href={
                  isRuLocale
                    ? process.env.REACT_APP_RU_COOKIES_CONCENT_URL || ''
                    : `https://kit.global/${
                        i18n.language !== defaultLanguage
                          ? i18n.language + '/'
                          : ''
                      }cookie-policy/`
                }
                target="_blank"
              >
                {t('Cookie Policy on cookies notice')}
              </Link>
              {'. '}
              {t('Are you happy to allow cookies?')}
            </div>
          </GridItem>
          <GridItem
            col={{ default: 12, sm: 5, md: 4 }}
            className={classes['cookie-notice__block-btn']}
          >
            <Button
              className={classes['cookie-notice__btn']}
              theme="primary"
              size="middle"
              onClick={handleAcceptCookies}
              disabled={isLoading}
            >
              {t('Accept all')}
            </Button>
            <Button
              className={classes['cookie-notice__btn']}
              theme="primary"
              size="middle"
              onClick={handleDeclineCookies}
              disabled={isLoading}
            >
              {t('Accept necessary')}
            </Button>
          </GridItem>
        </Grid>
      </div>
    </div>
  );
};
