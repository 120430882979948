export const pushToDataLayer = (
  category: string,
  action: string,
  event: string
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eCategory: category,
    eAction: action,
    event: event,
  });
};
