import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../contexts/AuthContext';
import { CookieNotice } from './CookieNotice';

export const RedirectAuth = observer(() => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [cookies] = useCookies(['cookie_consent']);

  useEffect(() => {
    if (toJS(authStore).isAuth) {
      navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, { replace: true });
    }
  }, [authStore.isAuth]);

  if (toJS(authStore).isAuth) {
    return (
      <Navigate to={`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`} replace />
    );
  }

  return (
    <>
      <Outlet />
      {!cookies.cookie_consent && <CookieNotice />}
    </>
  );
});
