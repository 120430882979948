import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { Input } from 'components/shared/Input';
import { PhoneInputMask } from 'components/shared/PhoneInput';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { Type } from 'components/shared/Type';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classes from './Personal.module.scss';
import { PersonalProps } from './Personal.props';
import { usePersonal } from './usePersonal';

export const Personal = ({ defaultValue, onSubmit }: PersonalProps) => {
  const {
    t,
    getLink,
    i18n,
    handleSubmit,
    onSubmitHandler,
    control,
    errors,
    setFullName,
    setJobTile,
    setPhone,
    disableSubmit,
  } = usePersonal(defaultValue, onSubmit);
  const email = defaultValue?.email;
  const formRef = useRef(null);

  return (
    <>
      <TitleAndDescription pageName="Account/personal" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} size={'xs-bold'} className={classes.title}>
            {t('Personal information')}
          </Type>
        </div>
        <p className={classes['info']}>
          {t(
            'By providing us with contact information you further agree on and accept the terms of our'
          )}{' '}
          <Link
            className={classes['info__link']}
            to={getLink(i18n.language)}
            target="_blank"
          >
            {t('Privacy Policy on account')}
          </Link>
        </p>

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <div className={classes.box}>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }} className={classes.item}>
                <div className={classes.field}>
                  <div className={classes['item-label']}>{t('Full name')}</div>
                  <Controller
                    name="fullName"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type={'text'}
                        placeholder={t('Enter your name')}
                        value={field.value}
                        error={!!errors.fullName}
                        maxLength={50}
                        onChange={(e) => {
                          field.onChange(e);
                          setFullName(e);
                        }}
                        isAccountForm
                        className={classes.input}
                      />
                    )}
                  />

                  {errors.fullName && (
                    <TooltipContainer
                      text={t('Invalid name')}
                      customClasses={'kit-ui-block'}
                      position={'top'}
                      className={classes.error}
                    >
                      <InfoIcon fill="#F5222D" width="18px" height="18px" />
                    </TooltipContainer>
                  )}
                </div>
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.email}>
                  <div className={classes['item-label']}>{t('Work email')}</div>
                  <Controller
                    name="email"
                    control={control}
                    render={() => (
                      <Input
                        type={'text'}
                        placeholder={t('Enter your email')}
                        className={classes.input}
                        value={email}
                        disabled
                        isAccountForm
                      />
                    )}
                  />
                </div>
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }} className={classes.item}>
                <div className={classes.field}>
                  <div className={classes['item-label']}>{t('Job title')}</div>
                  <Controller
                    name="jobTitle"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type={'text'}
                        placeholder={t('Enter your job title')}
                        value={field.value}
                        error={!!errors.jobTitle}
                        maxLength={254}
                        onChange={(e) => {
                          field.onChange(e);
                          setJobTile(e);
                        }}
                        className={classes.input}
                        isAccountForm
                      />
                    )}
                  />

                  {errors.jobTitle && (
                    <TooltipContainer
                      text={t('Invalid job title')}
                      customClasses={'kit-ui-block'}
                      position={'top'}
                      className={classes.error}
                    >
                      <InfoIcon fill="#F5222D" width="18px" height="18px" />
                    </TooltipContainer>
                  )}
                </div>
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  <div className={classes['item-label']}>
                    {t('Mobile number')}
                  </div>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInputMask
                        type={'number'}
                        placeholder={t('Enter your mobile')}
                        value={field.value}
                        error={!!errors.phone}
                        onChange={(e) => {
                          setPhone(e);
                          field.onChange(e);
                        }}
                        className={classes.input}
                        isAccountForm
                      />
                    )}
                  />

                  {errors.phone && (
                    <TooltipContainer
                      text={t('Invalid phone number')}
                      customClasses={'kit-ui-block'}
                      position={'top'}
                      className={classes.error}
                    >
                      <InfoIcon fill="#F5222D" width="18px" height="18px" />
                    </TooltipContainer>
                  )}
                </div>
              </GridItem>
            </Grid>
          </div>

          <div className={classes.submit}>
            <Button
              className={classes.button}
              theme="primary"
              size="middle"
              disabled={disableSubmit}
            >
              {t('Save changes')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
