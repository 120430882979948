import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ShowNotification } from 'tools/showNotification';
import { DefaultValueBusiness, OnSubmitBusiness } from './Business.props';
import { CompanyProduct } from '@type/common';

export const useBusiness = (
  products: CompanyProduct[],
  getParams: () => Promise<void>,
  defaultValue: DefaultValueBusiness,
  onSubmit: OnSubmitBusiness
) => {
  const { t, i18n } = useTranslation();
  const [selectedProducts, setSelectedProducts] = useState<
    { id: number; name: string }[]
  >([]);
  const productsOptions = products.map((product) => ({
    value: product.id,
    label: product.name,
  }));

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'all' });

  const notice = { type: '', text: '' };

  const [disableSubmit, setDisableSubmit] = useState(true);

  useEffect(() => {
    notice && getParams();
  }, []);

  useEffect(() => {
    if (defaultValue.companyProducts) {
      setSelectedProducts([...defaultValue.companyProducts]);
    }
  }, [defaultValue.companyProducts]);

  const callbacks = {
    onSelectProduct: (id: number) => {
      const selectedProduct = productsOptions.find(
        (product) => product.value === id
      );
      if (selectedProduct) {
        setSelectedProducts((prevSelected) => [
          ...prevSelected,
          {
            id: selectedProduct.value,
            name: selectedProduct.label,
          },
        ]);
      }
    },
    onDeleteSelectedProduct: (id: number) => {
      setSelectedProducts((prevSelected) =>
        prevSelected.filter((product) => product.id !== id)
      );
    },
  };

  useEffect(() => {
    let isInputValid = false;

    if (
      selectedProducts.length &&
      selectedProducts.length !== defaultValue.companyProducts?.length
    ) {
      isInputValid = true;
    }

    if (
      selectedProducts.length &&
      selectedProducts.length === defaultValue.companyProducts?.length
    ) {
      selectedProducts.forEach((product, index) => {
        if (product.id !== defaultValue.companyProducts[index].id) {
          isInputValid = true;
        }
      });
    }

    setDisableSubmit(!isInputValid);
  }, [selectedProducts]);

  const submit = async () => {
    onSubmit(
      selectedProducts.map((product) => product.id),
      () => {
        setDisableSubmit(true);
        ShowNotification({
          type: 'success',
          children: t('Profile successfully changed'),
        });
      },
      () => {
        setDisableSubmit(false);
        ShowNotification({
          type: 'error',
          children: t('Something went wrong'),
        });
      }
    );
  };

  const getLink = (language: string) => {
    if (language === 'en') {
      return 'https://kit.global/privacy-policy/';
    } else if (language === 'es') {
      return 'https://kit.global/es/privacy-policy/';
    } else if (language === 'ru') {
      return 'https://kokocgroup.ru/privacy/';
    }
    return '';
  };

  return {
    t,
    getLink,
    i18n,
    handleSubmit,
    submit,
    control,
    productsOptions,
    selectedProducts,
    callbacks,
    disableSubmit,
    isValid,
    notice,
  };
};
