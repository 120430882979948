import { AxiosResponse } from 'axios';
import api from 'services/http';
import {
  UpdateUserRequest,
  UpdateUserResponse,
  User,
  UserProductType,
} from '@type/user';
import { ListResponse } from '@type/api';
import { QueryParamTypes } from '@type/global';

export default class UserService {
  static async getUser(): Promise<AxiosResponse<User>> {
    return api.get<User>('/api/v1/partners/users/');
  }

  static async updateUser(
    requestData: UpdateUserRequest
  ): Promise<AxiosResponse<UpdateUserResponse>> {
    return api.put<UpdateUserResponse>('/api/v1/partners/users/', requestData);
  }

  static async sendRegistrationCompleteEmail(): Promise<AxiosResponse> {
    return api.post('/api/v1/auth/send_registration_complete_email/');
  }

  static async changeLanguage(language: string): Promise<AxiosResponse> {
    return api.put('/api/v1/partners/users/', {
      language: language,
    });
  }

  static async setOnboardingStatus(
    onboarding_partner_passed: boolean
  ): Promise<AxiosResponse> {
    return api.put('/api/v1/partners/users/', { onboarding_partner_passed });
  }

  static async getUserProducts(params?: {
    page?: number;
  }): Promise<AxiosResponse<ListResponse<UserProductType>>> {
    return api<ListResponse<UserProductType>>({
      url: '/api/v1/products/',
      method: 'get',
      params,
    });
  }

  static async getProjects(): Promise<AxiosResponse> {
    return api.get('/api/v1/projects/');
  }

  static async getProject(projectId: number): Promise<AxiosResponse> {
    return api
      .get(`api/v1/projects/${projectId}/`)
      .then((response) => response);
  }

  static async updateProject(projectId: number, goal_name: string) {
    return await api
      .post(`api/v1/projects/${projectId}/project_goal/`, {
        goal: { name: goal_name },
        project: projectId,
      })
      .then((response) => {
        if (response.status === 201) {
          return response?.data;
        }
      });
  }

  static async deleteGoal(projectId: number, goalId: number) {
    return await api
      .put(`api/v1/projects/${projectId}/project_goal/${goalId}/`)
      .then((response) => {
        if (response.status === 200) {
          return response?.data;
        }
      });
  }

  static async getInvoices(params?: QueryParamTypes): Promise<AxiosResponse> {
    const url = `/api/v1/finances/?${params || 'page=1'}`;
    return api.get(url);
  }

  static async getProjectInvoices(
    page = 1,
    projectId: number
  ): Promise<AxiosResponse> {
    const url = `/api/v1/projects/${projectId}/project_invoices/?page=${page}`;
    return api.get(url);
  }

  static async getPlatforms(): Promise<AxiosResponse> {
    return await api.get('/api/v1/platforms/');
  }
}
