import { TFunction } from 'i18next';
import classes from './PartnerProjects.module.scss';

export const projectsHints = (t: TFunction) => [
  {
    element: `.${classes['projects-wrapper']}`,
    title: t(
      'Projects appear once a client put your solution in their shopping cart and submitted a request to start the sales process. In the list, all projects are grouped by your solution. The project name is clickable and leads to a detailed page'
    ),
    intro: '',
    position: 'top',
    scrollTo: 'tooltip',
  },
];
