import logo_ru from 'assets/img/logo_ru.svg';
import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { LoginRuBGLeftIcon } from 'components/shared/Icons/LoginRuBGLeftIcon';
import { LoginRuBGRightIcon } from 'components/shared/Icons/LoginRuBGRightIcon';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import classes from './LoginRu.module.scss';
import { useTranslation } from 'react-i18next';
import { useWindowWidth } from 'hooks/useWindowWidth';

export const LoginContainerRu = observer(() => {
  const { isSmallTablet } = useWindowWidth();
  const { t } = useTranslation();

  const loginWithKokocID = (event: Event) => {
    event.preventDefault();
    window.location.replace(
      `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_SITE_ID}&3`
    );
  };

  return (
    <>
      <TitleAndDescription pageName="Login" />

      <div className={classes.container}>
        <NavLink to={`${process.env.REACT_APP_MAIN_SITE_URL}`}>
          <img src={logo_ru} alt="" className={classes.logo} />
        </NavLink>

        <div className={classes.form}>
          <div className={classes.title}>
            {isSmallTablet ? (
              <>
                {t('Kokoc').toLocaleUpperCase()}
                <br />
                {t('Group platform').toLocaleUpperCase()}
              </>
            ) : (
              t('Kokoc Group platform').toLocaleUpperCase()
            )}
          </div>

          <div className={classes.description}>
            &mdash; {isSmallTablet ? (
              <>
                {t('comprehensive solutions for')}
                <br />
                {t('your business development')}
              </>
            ) : (
              t('comprehensive solutions for your business development')
            )}
          </div>

          <div
            onClick={() =>
              (window.location.href = `${process.env.REACT_APP_KID_URL}?site_id=${process.env.REACT_APP_KID_URL}`)
            }
            style={{ width: '10px', height: '10px', cursor: 'pointer' }}
          />

          <Button
            className={clsx(classes.button, classes['submit-button'])}
            theme="light"
            onClick={loginWithKokocID}
          >
            {t('Login with KokocID')}
          </Button>
        </div>

        <div className={classes.right}>
          <LoginRuBGRightIcon />
        </div>

        <div className={classes.left}>
          <LoginRuBGLeftIcon />
        </div>
      </div>
    </>
  );
});
