import clsx from 'classnames';
import { Button } from 'components/shared/Button';
import { PurpleArrowLeftIcon } from 'components/shared/Icons/PurpleArrowLeftIcon';
import MultiSelect from 'components/shared/MultiSelect';
import { NoticeBox } from 'components/shared/NoticeBox';
import { NOTICE } from 'constants/enum';
import { addSolutionsThirdStepHints } from 'constants/onboarding';
import { Steps } from 'intro.js-react';
import { Controller } from 'react-hook-form';
import Input from '../../Input';
import Switcher from '../../Switcher';
import { multiFieldValues } from '../../utils';
import classes from '../FormBlock.module.scss';
import { ThirdStepProps } from './ThirdStep.props';
import { useThirdStep } from './useThirdStep';
import { thirdStepMessages } from '../validationSchemas';
import { useRef } from 'react';

const ThirdStep = ({
  disabled,
  setOpenCancelModal,
  setCurrentStep,
  isFormBlock,
  currentStep,
}: ThirdStepProps) => {
  const fieldRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const {
    isLaptop,
    enableHints,
    t,
    i18n,
    GOT_IT,
    NEXT,
    BACK,
    handleExit,
    isFree,
    errors,
    endIconOptions,
    control,
    internalPaymentOptions,
    selectedPaymentTypes,
    isMediaTablet,
    callbacks,
    internalCountryOptions,
    selectedCountries,
    paymentTypeOptions,
    setInternalPaymentOptions,
    countriesOptions,
    setInternalCountryOptions,
    onValidateClick,
    trigger,
    isCompletedFormSteps,
    clearErrors,
  } = useThirdStep(disabled, setCurrentStep, currentStep, fieldRefs);

  return (
    <div className={clsx(classes.thirdStep, disabled && classes.disabled)}>
      <Steps
        enabled={isLaptop && enableHints}
        steps={addSolutionsThirdStepHints(t)}
        options={{
          tooltipClass: clsx('customTooltip', classes.hintsTooltip),
          showBullets: false,
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      {isFormBlock && (
        <div className={classes.noticeBlock}>
          <NoticeBox
            type={NOTICE.WARNING}
            title={t('Please enter the minimum budget for your paid solution')}
            description={t(
              'This is the starting price needed to kick off the project. Our base currency is USD, prices are automatically converted to local currencies for Clients'
            )}
          />
        </div>
      )}

      <div className={classes.hintBlock}>
        <div className={classes.checkboxBLock}>
          <Controller
            render={({ field }) => (
              <Switcher
                isOn={field.value}
                setIsOn={() => {
                  field.onChange(!field.value);
                  clearErrors('is_free');
                }}
                label={t('Free solution')}
              />
            )}
            name="is_free"
          />
        </div>

        <div className={classes.priceBlock}>
          <div
            ref={(element) =>
              !!errors?.price && (fieldRefs.current.name = element)
            }
            className={clsx(classes.price, isFree && classes.disabledPrice)}
          >
            <Controller
              render={({ field }) => (
                <Input
                  placeholder={t('Price')}
                  value={field.value || 0}
                  onChange={(e) => {
                    field.onChange(e || 0);
                    clearErrors('price');
                  }}
                  title={t('Price')}
                  error={errors?.price?.message}
                  maxLength={16}
                  endIcon={endIconOptions(thirdStepMessages(t).price)}
                  inputForCurrency
                  errorText={errors?.price?.message}
                />
              )}
              name="price"
              control={control}
            />
          </div>

          <div className={classes.currencyBlock}>
            <div
              ref={(element) =>
                !!errors?.currency && (fieldRefs.current.name = element)
              }
              className={clsx(classes.currencyBlock__input, classes.disabled)}
            >
              <Controller
                render={({ field }) => (
                  <Input
                    value={field.value}
                    disabled
                    onChange={(value) => {
                      field.onChange(value);
                      clearErrors('currency');
                    }}
                    placeholder={t('Currency')}
                    title={t('Currency')}
                    error={errors?.currency?.message}
                    endIcon={endIconOptions(thirdStepMessages(t).currency)}
                  />
                )}
                name="currency"
                control={control}
              />
            </div>
          </div>
        </div>

        <div className={classes.paymentBlock}>
          <div
            ref={(element) =>
              !!errors?.payment_type && (fieldRefs.current.name = element)
            }
            className={clsx(classes.currencyBlock__select)}
          >
            <Controller
              render={({ field }) => (
                <MultiSelect
                  selected={multiFieldValues(
                    selectedPaymentTypes,
                    field,
                    i18n,
                    true
                  )}
                  options={internalPaymentOptions}
                  isSolutionForm
                  title={t('Payment type')}
                  placeholder=""
                  endIcon={endIconOptions(thirdStepMessages(t).payment_type)}
                  additionalClassName={classes.multiselectWrapper}
                  onSelect={(id) => {
                    callbacks.onSelectPaymentType(id, field.onChange);
                    clearErrors('payment_type');
                  }}
                  isSearch
                  multiline
                  contentHeight="50px"
                  contentWidth={isMediaTablet ? '103%' : 'unset'}
                  showCounter
                  searchPlaceholder={t('Search')}
                  onDeleteSelected={(id) => {
                    if (id === 'all') field.onChange(null);
                    callbacks.onDeletePaymentType(id, field.onChange);
                    paymentTypeOptions?.length === 1 && field.onChange(null);
                  }}
                  onSearch={(value) => {
                    paymentTypeOptions &&
                      setInternalPaymentOptions(
                        paymentTypeOptions?.filter((item) =>
                          String(item?.label)
                            ?.toLowerCase()
                            ?.includes(value.toLowerCase())
                        )
                      );
                  }}
                  error={errors?.payment_type?.message}
                />
              )}
              name="payment_type"
              control={control}
            />
          </div>
        </div>

        <div
          ref={(element) =>
            !!errors?.countries && (fieldRefs.current.name = element)
          }
          className={classes.countriesBlock}
        >
          <Controller
            render={({ field }) => (
              <MultiSelect
                selected={multiFieldValues(
                  selectedCountries,
                  field,
                  i18n,
                  true
                )}
                options={internalCountryOptions}
                isSolutionForm
                title={t('Country')}
                placeholder=""
                endIcon={endIconOptions(thirdStepMessages(t).countries)}
                additionalClassName={classes.multiselectWrapper}
                onSelect={(id) => {
                  callbacks.onSelectCountry(id, field.onChange);
                  clearErrors('countries');
                }}
                isSearch
                multiline
                contentHeight="50px"
                contentWidth={isMediaTablet ? '103%' : 'unset'}
                showCounter
                searchPlaceholder={t('Search')}
                onDeleteSelected={(id) => {
                  if (id === 'all') field.onChange(null);
                  callbacks.onDeleteCountry(id, field.onChange);
                  countriesOptions.length === 1 && field.onChange(null);
                }}
                onSearch={(value) => {
                  setInternalCountryOptions(
                    countriesOptions?.filter((item) =>
                      String(item?.label)
                        ?.toLowerCase()
                        ?.includes(value.toLowerCase())
                    )
                  );
                }}
                error={errors?.countries?.message}
              />
            )}
            name="countries"
            control={control}
          />
        </div>
      </div>

      {!disabled && (
        <div className={classes.bottomBlock}>
          <div className={classes.left}>
            <Button
              className={classes.button}
              theme="light"
              onClick={async (e: any) => {
                e.preventDefault();
                setCurrentStep?.(2);
                const isValid = await trigger();
                if (!isValid) {
                  isCompletedFormSteps.stepThree = false;
                }
              }}
            >
              <PurpleArrowLeftIcon />
              {t('Back')}
            </Button>

            <Button
              className={classes.button}
              theme="light"
              onClick={(e: any) => {
                e.preventDefault();
                setOpenCancelModal(true);
              }}
            >
              {t('Cancel')}
            </Button>
          </div>

          <Button
            className={classes.button}
            theme="default"
            onClick={async (e: any) => {
              e.preventDefault();
              await onValidateClick();
            }}
          >
            {t('Continue')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default ThirdStep;
