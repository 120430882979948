import clsx from 'clsx';
import { Grid, GridItem } from 'components/common/Grid';
import { LABELS } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isOnboardingActive } from 'tools/onboarding';
import classes from './Account.module.scss';
import { Business } from './Business';
import { ChangePassword } from './ChangePassword';
import { accountHints } from './hints';
import { Personal } from './Personal';
import PersonalManagerCard from './PersonalManagerCard';
import { Switch } from './Switch';
import { useAccountContainer } from './useAccountContainer';
import { useUserStore } from 'contexts/UserContext';
import { useCommonStore } from 'contexts/CommonContext';
import { RUSSIAN_LOCALE } from 'utils';

export const AccountContainer = observer(({ section }: { section: string }) => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const commonStore = useCommonStore();
  const { handleExit, callbacks, getParams } = useAccountContainer();
  const user = userStore.user;
  const { isSmallLaptop: isLaptop, isMediaTablet } = useWindowWidth();
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const [currentStep, setCurrentStep] = useState(0);
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.profile);

  const personalData = {
    fullName: user.full_name,
    email: user.email,
    phone: user.phone_number ? user.phone_number.substring(1) : '',
    jobTitle: user.user_role_in_company || '',
  };

  const businessData = {
    companyName: user.company_name,
    companyLink: user.company_link,
    companyLocation: user.country?.name || '',
    companyProducts: user.user_products,
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Steps
          enabled={isLaptop && enableHints}
          steps={accountHints(t)}
          options={{
            tooltipClass: clsx('customTooltip', classes.hintsTooltip),
            doneLabel: GOT_IT,
            nextLabel: NEXT,
            prevLabel: BACK,
          }}
          initialStep={currentStep}
          onComplete={() => {
            setCurrentStep(0);
            handleExit();
          }}
          onChange={(step: number) => {
            step >= 0 && setCurrentStep(step + 1);
          }}
          onExit={handleExit}
        />
        <Grid direction={'row'} className={classes.container}>
          <GridItem col={{ default: 12, xsup: 4, md: 3 }}>
            <Switch
              className={clsx(section && 'chosen')}
              section={currentStep ? '' : section}
              currentStep={currentStep}
            />
            {isMediaTablet && section ? null : (
              <PersonalManagerCard
                name={user.manager?.name}
                image={user.manager?.image}
              />
            )}
          </GridItem>

          <GridItem col={{ default: 12, xsup: 8, md: 6 }}>
            {section && (
              <div className={classes.innerBlock}>
                {(currentStep === 1 ||
                  (section === 'personal' && personalData && !currentStep)) && (
                  <Personal
                    onSubmit={callbacks.onSubmitPersonal}
                    defaultValue={personalData}
                    getParams={getParams}
                  />
                )}
                {(currentStep === 2 ||
                  (section === 'business' && businessData && !currentStep)) && (
                  <Business
                    onSubmit={callbacks.onSubmitBusiness}
                    products={commonStore.products}
                    defaultValue={businessData}
                    getParams={getParams}
                  />
                )}
                {!RUSSIAN_LOCALE &&
                  (currentStep === 3 ||
                    currentStep === 4 ||
                    (section === 'change-password' && !currentStep)) && (
                    <ChangePassword />
                  )}
              </div>
            )}
          </GridItem>
        </Grid>
      </div>
    </>
  );
});
