import clsx from 'clsx';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { LeftArrowIcon } from 'components/shared/Icons/LeftArrowIcon';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classes from './Switch.module.scss';
import { SwitchProps } from './Switch.props';
import { menuItems } from 'constants/links';

export const Switch = ({
  className: additionalClassname,
  section,
  currentStep,
}: SwitchProps) => {
  const { isMediaTablet, width } = useWindowWidth();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.wrapper,
        additionalClassname && classes[additionalClassname]
      )}
    >
      {isMediaTablet && (
        <div className={classes.mobileTitle}>{t('Account')}</div>
      )}

      <div className={classes.list}>
        {section && width < 960 && (
          <NavLink to={'/account'} className={classes['link-back']}>
            <LeftArrowIcon className={classes['link-back__arrow']} />
            <span>{t('Back to Account')}</span>
          </NavLink>
        )}

        {menuItems?.map(({ path, icon, nameKey, step, sectionKey }) => {
          if (path === '') return;
          return (
            <NavLink
              key={`${nameKey}_${sectionKey}`}
              to={section && width <= 960 ? '/account' : path}
              className={clsx(
                classes.link,
                width >= 960 &&
                  (currentStep === step || section === sectionKey) &&
                  classes.active
              )}
            >
              <div className={classes.icon}>{icon}</div>
              <div className={classes.back}>
                <ArrowDownIcon fill={'#898989'} width={'16px'} height={'8px'} />
              </div>
              <div className={classes.name}>{t(nameKey)}</div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
