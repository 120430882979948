import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n/i18n';
import { RUSSIAN_LOCALE } from '../utils';
import { AxiosError, AxiosResponse } from 'axios';
import { UpdateUserRequest, UpdateUserResponse, User } from '@type/user';
import { OnboardingPages } from 'types/global';
import UserService from 'services/user';

export class UserStore {
  user: User = {} as User;
  isComplete = false;
  isDataLoading = false;
  isInnerDataLoading = false;
  isPermissionError = false;
  isLocaleError = false;
  onboardingStatus = false;
  onBoardingComplete = false;
  onboarding_partner_passed = false;
  pagesToOnboard: OnboardingPages = {
    solutionsEmpty: false,
    solutionsWithCard: false,
    solutionsDetail: false,
    solutionMain: false,
    solutionCases: false,
    solutionCasesForm: false,
    solutionPrice: false,
    projects: false,
    projectsDetail: false,
    finances: false,
    profile: false,
  };
  localeChanged = false;
  currentLanguage: string | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setUser(user: User) {
    this.user = user;
  }

  setLocaleChanged = (val: boolean) => {
    this.localeChanged = val;
  };

  loadData(isDataLoading?: boolean, isInnerDataLoading?: boolean) {
    if (isDataLoading !== undefined) {
      this.isDataLoading = isDataLoading;
    }
    if (isInnerDataLoading !== undefined) {
      this.isInnerDataLoading = isInnerDataLoading;
    }
  }

  public async loadUser(
    onError?: (error: AxiosError) => void
  ): Promise<User | undefined> {
    try {
      const response = await UserService.getUser();
      this.setUser(response?.data);
      const userLocale: string | null =
        response?.data?.language?.lang_code || null;
      const userOffice: string | null =
        response?.data?.user_office?.name || null;

      if (RUSSIAN_LOCALE) {
        this.currentLanguage = 'ru';
        if (userOffice !== null && userOffice !== 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'ru';
        }
        await this.changeLanguage(this.currentLanguage);
      } else {
        if (userOffice !== null && userOffice === 'Kokoc Group') {
          this.isLocaleError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        } else {
          this.currentLanguage = userLocale || 'en';
          await this.changeLanguage(this.currentLanguage);
        }
      }
      if (!RUSSIAN_LOCALE && i18n.language !== this.currentLanguage) {
        await this.changeLanguage(this.currentLanguage);
      }
      this.onboardingStatus = response?.data?.onboarding_partner_passed;

      if (response?.data) {
        runInAction(() => {
          this.isComplete = true;
        });
      }

      return response?.data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 403) {
        const userLocale: string | null =
          error.response?.data?.language?.lang_code || null;
        runInAction(async () => {
          this.isPermissionError = true;
          this.currentLanguage = userLocale || 'en';
          await i18n.changeLanguage(this.currentLanguage);
        });

        onError?.(error as AxiosError);
      }
    }
  }

  public async updateUser(
    requestData: UpdateUserRequest,
    onSuccess?: (result: AxiosResponse<UpdateUserResponse>) => void,
    onError?: (error: AxiosError) => void
  ): Promise<void> {
    try {
      const result = await UserService.updateUser(requestData);

      runInAction(() => {
        this.setUser(result.data.user);
      });

      onSuccess?.(result);
    } catch (error) {
      onError?.(error as AxiosError);
    }
  }

  public async sendRegistrationCompleteEmail(
    onSuccess?: () => void,
    onError?: (error: AxiosError) => void
  ) {
    try {
      await UserService.sendRegistrationCompleteEmail();
      onSuccess?.();
    } catch (error) {
      onError?.(error as AxiosError);
    }
  }

  public async changeLanguage(language: string): Promise<boolean> {
    try {
      const response = await UserService.updateUser({ language });
      if (response.data.is_valid === true) {
        await i18n.changeLanguage(language);
        this.setUser(response.data.user);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public async enableOnboarding(enable: boolean): Promise<void> {
    const options: Partial<OnboardingPages> =
      JSON?.parse(localStorage.getItem('onboardingComplete') || '{}') || {};

    const {
      solutionsEmpty,
      solutionsWithCard,
      solutionMain,
      solutionCases,
      solutionCasesForm,
      solutionPrice,
      solutionsDetail,
      profile,
      projects,
      projectsDetail,
      finances,
    } = options;

    const isOnboardingPassed = Object.values({
      solutionsEmpty,
      solutionsWithCard,
      solutionMain,
      solutionCases,
      solutionCasesForm,
      solutionPrice,
      solutionsDetail,
      profile,
      projects,
      projectsDetail,
      finances,
    }).every(Boolean);

    if (isOnboardingPassed) {
      await UserService.updateUser({ onboarding_partner_passed: true });
    }

    this.onBoardingComplete = enable;
    if (enable) {
      this.pagesToOnboard = Object.keys(options)?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: options[curr as keyof OnboardingPages] || false,
        }),
        {} as OnboardingPages
      );
    }

    if (isOnboardingPassed && enable) {
      sessionStorage.setItem('onboarding', 'false');
    }
  }

  clearStore() {
    this.user = {} as User;
    this.isComplete = false;
    this.onBoardingComplete = false;
    this.enableOnboarding(false);
    localStorage.setItem('onboardingComplete', JSON.stringify({}));
  }
}

export const userStore = new UserStore();
