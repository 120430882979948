import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import Modals from '../Modals';
import { initialSteps, materialsData } from '../utils';
import FirsStep from './FirstStep';
import classes from './FormBlock.module.scss';
import { FormBlockProps } from './FormBlock.props';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import { useFormBlock } from './useFormBlock';
import { PageLoader } from 'components/shared/PageLoader';
import FourthStep from './FourthStep';

export const FormBlock = observer(
  ({
    setCurrentStep,
    currentStep,
    copiedService,
    isDisabled,
    isFormBlock,
    openCaseModal,
    setOpenCaseModal,
  }: FormBlockProps) => {
    const {
      methods,
      productTypes,
      industryTypes,
      dictionaries,
      formatDictionaryTypes,
      internalData,
      goals,
      setOpenCancelModal,
      platforms,
      setOpenSubmitModal,
      openCancelModal,
      openSubmitModal,
      handleSubmit,
      handleCancel,
      t,
      isCreating,
    } = useFormBlock(copiedService, currentStep);

    return (
      <div className={classes.formBlock}>
        <form className={classes.form}>
          {currentStep === 1 && (
            <FirsStep
              productTypes={productTypes}
              industryTypes={industryTypes || []}
              companySizes={formatDictionaryTypes(dictionaries?.company_size)}
              serviceTypes={formatDictionaryTypes(dictionaries?.service_types)}
              initialSteps={internalData?.stages || initialSteps(t)}
              defaultGoals={goals}
              setCurrentStep={setCurrentStep}
              setOpenCancelModal={setOpenCancelModal}
              materialsData={materialsData(t)}
              copiedService={internalData}
              disabled={isDisabled}
              currentStep={currentStep}
            />
          )}

          {currentStep === 2 && (
            <Controller
              render={({ field }) => (
                <SecondStep
                  setCurrentStep={setCurrentStep}
                  setOpenCancelModal={setOpenCancelModal}
                  platforms={platforms}
                  disabled={isDisabled}
                  defaultValues={field.value}
                  currentStep={currentStep}
                  openCasesModal={openCaseModal}
                  setOpenCasesModal={setOpenCaseModal}
                />
              )}
              name="cases"
              control={methods.control}
            />
          )}

          {currentStep === 3 && (
            <ThirdStep
              setCurrentStep={setCurrentStep}
              setOpenSubmitModal={setOpenSubmitModal}
              setOpenCancelModal={setOpenCancelModal}
              disabled={isDisabled}
              isFormBlock={isFormBlock}
              currentStep={currentStep}
            />
          )}

          {currentStep === 4 && (
            <FourthStep
              setCurrentStep={setCurrentStep}
              setOpenCancelModal={setOpenCancelModal}
              disabled={isDisabled}
              setOpenSubmitModal={setOpenSubmitModal}
              t={t}
              isFormBlock={isFormBlock}
              currentStep={currentStep}
            />
          )}
        </form>

        <Modals
          openCancelModal={openCancelModal}
          setOpenCancelModal={setOpenCancelModal}
          openSubmitModal={openSubmitModal}
          setOpenSubmitModal={setOpenSubmitModal}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          currentStep={currentStep}
        />

        {isCreating && <PageLoader />}
      </div>
    );
  }
);
