import { Logo } from 'components/common/Logo';
import { User } from 'components/common/User';
import { Button } from 'components/shared/Button';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { MenuGradientIcon } from 'components/shared/Icons/MenuGradientIcon';
import { LinksPolitic } from 'components/shared/LinksPolitic';
import { Menu } from 'components/shared/Menu';
import { Modal } from 'components/shared/Modal';
import { languages } from 'constants/languages';
import { NavLink } from 'react-router-dom';
import classes from './Header.module.scss';
import { LocalPreferencesForm } from './LocalPreferences';
import clsx from 'clsx';
import { AdminGradientIcon } from 'components/shared/Icons/AdminGradientIcon';
import { AdminGradientIconActive } from 'components/shared/Icons/AdminGradientIconActive';
import { LogoutGradientIcon } from 'components/shared/Icons/LogoutGradientIcon';
import { SearchGradientIcon } from 'components/shared/Icons/SearchGradientIcon';
import { ShapeGradientIcon } from 'components/shared/Icons/ShapeGradientIcon';
import { SearchComponent } from 'components/shared/SearchComponent';
import { observer } from 'mobx-react-lite';
import { HeaderProps } from './Header.props';
import { useHeader } from './useHeader';
import HeaderButtons from './HeaderButtons/HeaderButtons';

export const Header = observer(
  ({ disabled = false, allowLogout }: HeaderProps) => {
    const {
      width,
      active,
      bottomMenuSize,
      disableHeader,
      t,
      isSuccessLocalPreferences,
      isActiveLink,
      path,
      userName,
      openSearch,
      setOpenSearch,
      closeMenu,
      openMenu,
      isDisabledModerate,
      isManager,
      isAdmin,
      resetCookies,
      isTestUser,
      isDisabledLocalPreferences,
      handleSendLocalPreferences,
      handleCloseLocalPreferencesModal,
      isOpenLocalPreferencesModal,
      isMediaTablet,
      i18n,
      userCountry,
      currencies,
      countries,
      userCurrency,
    } = useHeader();

    return (
      <>
        <header
          className={clsx(classes['header'], active && classes['active'])}
          id="global-header-id"
        >
          <div className={classes['header__inner']}>
            <Logo />
            <div className={classes['header__activation']}>
              <div
                className={classes['header__search-icon']}
                onClick={() => !disableHeader && setOpenSearch(!openSearch)}
              >
                <SearchGradientIcon />
              </div>

              <div className={clsx(classes['header__burger'])}>
                {!active ? (
                  <Button
                    className={clsx(
                      disabled && !allowLogout && classes.disabled
                    )}
                    onClick={openMenu}
                  >
                    <MenuGradientIcon />
                  </Button>
                ) : (
                  <Button onClick={closeMenu}>
                    <CrossIcon
                      width={'15px'}
                      height={'15px'}
                      fill={'#252529'}
                    />
                  </Button>
                )}
              </div>
            </div>
          </div>

          <nav className={classes['header__nav']}>
            <Menu
              active={active}
              disabled={disableHeader}
              onModerate={isDisabledModerate}
              closeMenu={closeMenu}
            />
            <div className={classes['header__block']}>
              <div className={classes['header__search']}>
                {(openSearch || width > 1024) && (
                  <SearchComponent
                    disabled={disableHeader}
                    setOpenSearch={setOpenSearch}
                  />
                )}
              </div>

              <ul className={classes['header__list']}>
                <li
                  className={clsx(
                    classes['header__list-item'],
                    classes['header__user']
                  )}
                >
                  <NavLink
                    to={disableHeader ? '#' : path}
                    className={clsx(
                      classes['header__list-link'],
                      isActiveLink && !disableHeader && classes.active
                    )}
                    onClick={(e) => {
                      disableHeader && e.preventDefault();
                    }}
                  >
                    <User name={userName} />
                  </NavLink>

                  <div className={classes.modal}>
                    <ul className={classes.modalList}>
                      <li
                        className={clsx(
                          classes.modalItem,
                          disableHeader && classes.disabled
                        )}
                      >
                        <NavLink
                          to={path}
                          className={(navData) =>
                            clsx(
                              classes.modalLink,
                              navData.isActive &&
                                !disableHeader &&
                                classes.modalLinkActive
                            )
                          }
                        >
                          <div className={classes.modalInner}>
                            <div className={classes.modalIcon}>
                              <ShapeGradientIcon />
                            </div>
                            <span className={classes.modalName}>
                              {t('Profile')}
                            </span>
                          </div>
                        </NavLink>
                      </li>

                      {(isAdmin || isManager) && (
                        <li
                          className={clsx(
                            classes.modalItem,
                            disableHeader && classes.disabled
                          )}
                        >
                          <NavLink
                            to={`${process.env.REACT_APP_API}/globaladmin/`}
                            className={classes.modalLink}
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <AdminGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t('Admin panel')}
                              </span>
                            </div>
                          </NavLink>
                        </li>
                      )}

                      {!isTestUser && (
                        <li className={classes.modalItem}>
                          <Button
                            onClick={resetCookies}
                            className={classes.modalButton}
                          >
                            <div className={classes.modalInner}>
                              <div className={classes.modalIcon}>
                                <LogoutGradientIcon />
                              </div>
                              <span className={classes.modalName}>
                                {t('Log out')}
                              </span>
                            </div>
                          </Button>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            {width <= 1024 && active && (
              <ul className={classes['header__admin-list']}>
                {(isAdmin || isManager) && (
                  <li
                    className={clsx(
                      classes['header__admin-list-item'],
                      disableHeader && classes.disabled
                    )}
                  >
                    <NavLink
                      to={`${process.env.REACT_APP_API}/globaladmin/`}
                      className={classes.modalLink}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <AdminGradientIconActive />
                        </div>
                        <span className={classes.modalName}>
                          {t('Admin panel')}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                )}

                {!isTestUser && (
                  <li className={classes['header__admin-list-item']}>
                    <Button
                      onClick={resetCookies}
                      className={clsx(classes.modalButton)}
                    >
                      <div className={classes.modalInner}>
                        <div className={classes.modalIcon}>
                          <LogoutGradientIcon />
                        </div>
                        <span className={classes.modalName}>
                          {t('Log out')}
                        </span>
                      </div>
                    </Button>
                  </li>
                )}
              </ul>
            )}

            {active && isMediaTablet && (
              <footer className={clsx(classes['burger-footer'])}>
                <div className={classes['burger-footer__inner']}>
                  <div className={classes['burger-footer__copy']}>
                    &copy; 2023 KIT Global
                  </div>
                  <LinksPolitic />
                </div>
              </footer>
            )}
          </nav>

          <Modal
            isOpen={isOpenLocalPreferencesModal}
            title={t('Local preferences')}
            onClose={handleCloseLocalPreferencesModal}
          >
            <LocalPreferencesForm
              onClose={handleCloseLocalPreferencesModal}
              onSave={handleSendLocalPreferences}
              success={isSuccessLocalPreferences}
              disabled={isDisabledLocalPreferences}
              noticeText={t(
                'Local settings affect the interface language and prices you see on the platform'
              )}
              countries={countries}
              currencies={currencies}
              languages={languages}
              currentCountry={userCountry}
              currentCurrency={userCurrency}
              currentLanguage={languages.find(
                (item) => item.lang_code === i18n.language
              )}
              disabledFields={['country']}
            />
          </Modal>
        </header>

        {width <= 1024 && !active && (
          <HeaderButtons
            bottomMenuSize={bottomMenuSize}
            disableHeader={disableHeader}
            path={path}
            allowLogout={allowLogout}
            userName={userName}
            isSuccessLocalPreferences={isSuccessLocalPreferences}
            isActiveLink={isActiveLink}
          />
        )}
      </>
    );
  }
);
