import { yupResolver } from '@hookform/resolvers/yup';
import { FormLogin } from '@type/auth';
import { useAuthStore } from 'contexts/AuthContext';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { REGEX_EMAIL_254 } from 'constants/matches';
import * as yup from 'yup';

export const useLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [recaptcha, setRecaptcha] = useState('');
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const navigate = useNavigate();
  const authStore = useAuthStore();
  const { i18n, t } = useTranslation();

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(REGEX_EMAIL_254, t('Enter a valid email address'))
      .required(t('The email address or password is incorrect')),
    password: yup.string().required(t('This field is required')),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<FormLogin>({ resolver: yupResolver(schema), mode: 'onSubmit' });

  useEffect(() => {
    if (email !== '' && password !== '' && recaptcha !== '') {
      setDisableSubmit(false);
      return;
    }

    setDisableSubmit(true);
  }, [email, password, recaptcha]);

  const onSubmit: SubmitHandler<FormLogin> = async (data: FormLogin) => {
    const { email, password } = data;
    try {
      const res = await authStore.login(email, password, recaptcha);

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      const { form_errors, status } = res || {};

      if (form_errors) {
        if (form_errors.email) {
          setError('email', { type: 'server', message: form_errors.email });
        }
        if (form_errors.password) {
          setError('password', {
            type: 'server',
            message: form_errors.password,
          });
        }
        if (form_errors.__all__) {
          setError('email', {
            type: 'server',
            message: t('The email address or password is incorrect'),
          });
        }
      } else if (status) {
        navigate(process.env.REACT_APP_LOGIN_SUCCESS_URL || '');
      }
    } catch (error: any) {
      setError('email', { type: 'server', message: error.message });
    }
  };

  return {
    t,
    i18n,
    errors,
    control,
    handleSubmit,
    onSubmit,
    disableSubmit,
    recaptchaRef,
    setRecaptcha,
    setEmail,
    setPassword,
  };
};
