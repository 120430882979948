import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import classes from './PartnerProjects.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import { TabFilter } from 'components/shared/TabFilter';
import { PlusIcon } from 'components/shared/Icons/PlusIcon';
import { SolutionType } from './types';
import ProjectsTable from './ProjectsTable';
import ProjectsList from './ProjectsList';
import UiIcon from 'components/shared/Icon';
import { Button } from 'components/shared/Button';
import Select from 'components/shared/SelectComponent';
import clsx from 'clsx';
import {
  defaultSortParams,
  projectsQueryParamsHandler,
  selectOptions,
  selectOptionsRu,
} from './utils';
import { TTab } from '../../components/shared/TabFilter/types';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { useUserStore } from 'contexts/UserContext';
import { LABELS } from 'constants/onboarding';
import { Steps } from 'intro.js-react';
import { projectsHints } from './hints';
import { RUSSIAN_LOCALE } from 'utils';
import { getLangName } from '../../tools/utils';
import { useProjectsStore } from 'contexts/ProjectsContext';

const tempFilters = [
  {
    id: 1,
    name: 'All',
    count: 0,
  },
  {
    id: 2,
    name: 'Active',
    count: 0,
  },
  {
    id: 3,
    name: 'Completed',
    count: 0,
  },
  {
    id: 4,
    name: 'Paused',
    count: 0,
  },
  {
    id: 5,
    name: 'Cancelled',
    count: 0,
  },
];

export const ProjectsContainer = observer(() => {
  const { t, i18n } = useTranslation();
  const { isMediaTablet, isPhone, isSmallLaptop: isLaptop } = useWindowWidth();
  const projectsStore = useProjectsStore();
  const userStore = useUserStore();

  const [openSorting, setOpenSorting] = useState(false);
  const [selectSort, setSelectSort] = useState(
    RUSSIAN_LOCALE ? selectOptionsRu(t)[0] : selectOptions(t)[0]
  );
  const [activeTab, setActiveTab] = useState(tempFilters[0]);
  const [internalProjects, setInternalProjects] = useState<SolutionType[]>([]);
  const [allProjectsLength, setAllProjectsLength] = useState(0);
  const enableHints =
    isOnboardingActive(userStore?.pagesToOnboard?.projects) &&
    internalProjects?.length > 0;
  const { GOT_IT, NEXT, BACK } = LABELS(t);
  const isEmpty = internalProjects?.length === 0;

  const handleExit = useCallback(() => {
    if (allProjectsLength > 0) {
      setOnboardingOptions({ projects: true });
      userStore.enableOnboarding(true);
    }
  }, [userStore.enableOnboarding, allProjectsLength]);

  const selectParam = defaultSortParams(selectSort);

  const projectInfo: SolutionType[] = useMemo(() => {
    setAllProjectsLength(0);
    return projectsStore.solutionsWithProjects?.map((solution) => {
      setAllProjectsLength(
        (prevProjects) => prevProjects + solution.projects.length
      );
      return {
        id: solution?.id,
        solution: {
          id: solution?.id,
          name: getLangName(solution, 'name', i18n),
          totalCost: solution?.total_cost,
          currency: solution?.total_cost_currency,
          statusCount: solution?.project_status_count,
        },
        projects: solution?.projects?.map((project) => ({
          id: project.number,
          company: project.company_name || '',
          source: project?.source,
          cost: project.cost[0]?.cost,
          currency: project.currency,
          type: project?.cost[0]?.type,
          status: project?.status,
          serial: 0,
          solutionId: solution?.id,
          projectId: project.id,
        })),
      };
    });
  }, [projectsStore.solutionsWithProjects, i18n.language]);

  const [filters, setFilters] = useState([
    { id: 1, name: 'All', count: 0 },
    { id: 2, name: 'Active', count: 0 },
    { id: 3, name: 'Completed', count: 0 },
    { id: 4, name: 'Paused', count: 0 },
    { id: 5, name: 'Cancelled', count: 0 },
  ]);

  const filtersInitializedRef = useRef(false);

  useEffect(() => {
    if (internalProjects.length > 0 && !filtersInitializedRef.current) {
      const initializeFilters = () => {
        const updatedFilters = filters.map((filter) => ({
          ...filter,
          count: 0,
        }));

        internalProjects.forEach((solution) => {
          solution.solution?.statusCount?.forEach((status) => {
            updatedFilters.forEach((filter) => {
              if (filter.name.toLowerCase() === 'all') {
                filter.count += status.count;
              }
              if (filter.name.toLowerCase() === status?.type?.toLowerCase()) {
                filter.count += status.count;
              }
            });
          });
        });

        setFilters(updatedFilters);
      };

      initializeFilters();
      filtersInitializedRef.current = true;
    }
  }, [internalProjects]);

  useEffect(() => {
    setInternalProjects(projectInfo);
  }, [projectsStore.solutionsWithProjects]);

  useEffect(() => {
    const section = document.getElementsByClassName('app__section')[0];
    if (section) {
      if (isEmpty) {
        section.classList.add('app__section_gradient');
      } else {
        section.classList.remove('app__section_gradient');
      }
    }
  }, [isEmpty]);

  const onTabClick = async (tab: TTab) => {
    const params = projectsQueryParamsHandler({
      order: selectParam,
      page_size: 10,
      page: 1,
      status: tab.name.toLowerCase() === 'all' ? '' : tab.name.toUpperCase(),
    });
    await projectsStore.loadSolutionsWithProjects(params);
    setActiveTab(tab);
  };

  const onSortChange = async (item: { id: number }) => {
    const params = projectsQueryParamsHandler({
      order: defaultSortParams(item),
      page_size: 10,
      page: 1,
    });
    await projectsStore.loadSolutionsWithProjects(params);
  };

  return (
    <>
      <Steps
        enabled={isLaptop && enableHints}
        steps={projectsHints(t)}
        options={{
          tooltipClass: clsx(
            'customTooltip',
            classes.hintsTooltip,
            classes['hintsTooltip__top--center']
          ),
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
          showBullets: false,
          autoPosition: false,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />
      <TitleAndDescription pageName={'Projects'} />
      <div className={classes.wrapper}>
        <div className={classes['flex-wrapper']}>
          <span className={classes.title}>
            {t('Projects')}
            <span>{projectsStore.allProjectsCount}</span>
          </span>
          {isMediaTablet && (
            <Button onClick={() => setOpenSorting(true)} theme="icon-default">
              <UiIcon
                name="FilterMobileTableIcon"
                additionalClassName={classes['mobile-sort']}
              />
            </Button>
          )}
        </div>
        <div
          className={clsx(
            classes['flex-wrapper'],
            classes['flex-wrapper__tabs']
          )}
        >
          <TabFilter
            addClassName={classes.tabs}
            tabArray={filters}
            activeTab={isEmpty ? null : activeTab}
            onClick={onTabClick}
            disabled={isEmpty}
          />

          <Select
            select={selectSort}
            setSelect={setSelectSort}
            options={RUSSIAN_LOCALE ? selectOptionsRu(t) : selectOptions(t)}
            title={t('Sort by')}
            open={openSorting}
            setOpen={setOpenSorting}
            onSelect={onSortChange}
          />
        </div>
        {!allProjectsLength && (
          <>
            <div className={classes.empty}>
              <div className={classes.empty__header}>
                {t('You have no projects')}
              </div>
              <div className={classes.empty__text}>
                {t(
                  'You will see your projects after a client orders your first solution. To increase your chances, add more solutions'
                )}
              </div>
              <a
                className={classes.empty__link}
                href="/solutions/add-solution/"
              >
                <PlusIcon />
                {t('Add solution')}
              </a>
            </div>

            {isPhone && (
              <a
                style={{ marginTop: '280px' }}
                className={classes.empty__link}
                href="/solutions"
              >
                <PlusIcon />
                {t('Add solution')}
              </a>
            )}
          </>
        )}
        {allProjectsLength > 0 && (
          <div className={classes['projects-wrapper']}>
            {isMediaTablet ? (
              <ProjectsList data={internalProjects.slice(0, 5)} />
            ) : (
              <ProjectsTable data={internalProjects} />
            )}
          </div>
        )}
      </div>
    </>
  );
});
