import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ShowNotification } from 'tools/showNotification';
import { personalSchema } from '../validationSchemas';
import {
  DefaultValuePersonal,
  OnSubmitPersonal,
  PersonalFormInputs,
} from './Personal.props';

export const usePersonal = (
  defaultValue: DefaultValuePersonal,
  onSubmit: OnSubmitPersonal
) => {
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalFormInputs>({
    resolver: yupResolver(personalSchema),
    mode: 'all',
    defaultValues: {
      fullName: defaultValue.fullName,
      email: defaultValue.email,
      phone: defaultValue.phone,
      jobTitle: defaultValue.jobTitle,
    },
  });

  const [fullName, setFullName] = useState<string>(defaultValue.fullName);
  const [jobTitle, setJobTile] = useState<string>(defaultValue.jobTitle);
  const [phone, setPhone] = useState<string | null>(defaultValue.phone);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const onSubmitHandler = (value: PersonalFormInputs) => {
    onSubmit(
      value,
      () => {
        setDisableSubmit(true);
        ShowNotification({
          type: 'success',
          children: t('Profile successfully changed'),
        });
      },
      () => {
        setDisableSubmit(false);
        ShowNotification({
          type: 'error',
          children: t('Something went wrong'),
        });
      }
    );
  };

  useEffect(() => {
    if (
      fullName !== '' &&
      !errors.fullName &&
      jobTitle !== '' &&
      !errors.jobTitle &&
      phone &&
      phone.length > 10
    ) {
      if (
        fullName !== defaultValue.fullName ||
        phone !== defaultValue.phone ||
        jobTitle !== defaultValue.jobTitle
      ) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  }, [errors?.fullName, fullName, phone, errors?.jobTitle, jobTitle]);

  const getLink = (language: string) => {
    if (language === 'en') {
      return 'https://kit.global/privacy-policy/';
    } else if (language === 'es') {
      return 'https://kit.global/es/privacy-policy/';
    } else if (language === 'ru') {
      return 'https://kokocgroup.ru/privacy/';
    }
    return '';
  };

  return {
    t,
    getLink,
    i18n,
    handleSubmit,
    onSubmitHandler,
    control,
    errors,
    setFullName,
    setJobTile,
    setPhone,
    disableSubmit,
  };
};
