import { LockGradientIcon } from 'components/shared/Icons/LockGradientIcon';
import { ShapeGradientIcon } from 'components/shared/Icons/ShapeGradientIcon';
import { WalletGradientIcon } from 'components/shared/Icons/WalletGradientIcon';
import { RUSSIAN_LOCALE } from 'utils';

export const menuItems = [
  {
    path: '/account/personal',
    icon: <ShapeGradientIcon />,
    nameKey: 'Personal information',
    step: 1,
    sectionKey: 'personal',
  },
  {
    path: '/account/business',
    icon: <WalletGradientIcon />,
    nameKey: 'Business information',
    step: 2,
    sectionKey: 'business',
  },
  {
    path: !RUSSIAN_LOCALE ? '/account/change-password' : '',
    icon: <LockGradientIcon />,
    nameKey: 'Change password',
    step: 3,
    sectionKey: 'change-password',
  },
];
