import { AxiosResponse } from 'axios';
import api from './http';
import { RegisterResponseFail, RegisterResponseSuccess } from '@type/auth';

export default class AuthService {
  static async login(
    email: string,
    password: string,
    recaptcha: string
  ): Promise<AxiosResponse> {
    return api.post(
      '/login/',
      { email, password, 'g-recaptcha-response': recaptcha },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
  }

  static async logout(): Promise<AxiosResponse> {
    return api.post('/logout/');
  }

  static async register(
    requestData: FormData
  ): Promise<AxiosResponse<RegisterResponseSuccess | RegisterResponseFail>> {
    return api.post('/api/v1/partners/register/', requestData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }
}
