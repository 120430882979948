import { userStore } from './stores/user-store';
import { authStore } from './stores/authStore';
import { partnerStore } from './stores/partnerStore';
import { queryParamsHandler } from './containers/PartnerContainer/utils';
import {
  defaultSortParams,
  projectsQueryParamsHandler,
} from './containers/Projects/utils';
import { financesStore } from 'stores/financesStore';
import { commonStore } from './stores/commonStore';
import { projectsStore } from 'stores/projects-store';
import { requestDataOptionTypes } from '@type/util-types';
import { Params } from 'react-router-dom';

export const requestData = async (
  options: requestDataOptionTypes,
  params?: Params<string>
) => {
  const {
    invoices = false,
    projects = false,
    user = false,
    serviceItem = false,
    auth = false,
    projectInfo = false,
    partnerServices = false,
    products = false,
    platforms = false,
    countries = false,
    kpiOptions = false,
    dictionaries = false,
  } = options;

  userStore.loadData(true);
  const isInvoicesFull = financesStore?.finances?.results?.sub_invoices?.length;
  const isAuthFull = authStore?.accessToken;
  const isDictionariesFull = commonStore?.dictionaries;
  const servicesParams = queryParamsHandler({
    analytics: true,
  });
  const sortProjectParams = projectsQueryParamsHandler({
    order: defaultSortParams({ id: 1 }),
    page_size: 10,
    page: 1,
  });
  const promises = [
    user ? await userStore.loadUser() : {},
    isInvoicesFull && !invoices
      ? {}
      : invoices
        ? await financesStore.loadFinances()
        : {},
    projects
      ? await projectsStore.loadSolutionsWithProjects(sortProjectParams)
      : {},
    serviceItem ? await partnerStore.fetchSingleService(params?.id || '') : {},
    isAuthFull && auth ? {} : auth ? await authStore.verifyAuth() : {},
    projectInfo ? await projectsStore.loadProject(params?.id || '') : {},
    partnerServices &&
    userStore?.user?.email_confirmed &&
    userStore?.user?.currency?.id
      ? await partnerStore.fetchPartnerServices(servicesParams)
      : {},
    products ? await commonStore.loadCompanyProducts() : {},
    platforms ? await commonStore.loadPlatforms() : {},
    countries ? await commonStore.loadCountries({ all: true }) : {},
    kpiOptions ? await commonStore.getCaseKpiOptions() : {},
    isDictionariesFull && !dictionaries
      ? {}
      : dictionaries
        ? await commonStore.loadDictionaries()
        : {},
  ];
  try {
    if (!authStore.isServerError) {
      const results = await Promise.all(promises);
      userStore.loadData(false);
      return {
        services: results[0],
        invoices: results[1],
        projects: results[2],
        cart: results[3],
        types: results[4],
      };
    }
  } catch (error) {
    console.error('Error loading data:', error);
    throw error;
  }
};

export const RUSSIAN_LOCALE = process.env.REACT_APP_PLATFORM_TYPE === 'ru';
// export const RUSSIAN_LOCALE = true;
