import { useDisableScrolling } from 'hooks/useDisableScrolling';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useCardManager = () => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bigQuestionLength, setBigQuestionLength] = useState(false);
  const [questionErrorMessage, setQuestionErrorMessage] = useState('');
  const [bigSourceLength, setBigSourceLength] = useState(false);
  const [sourceErrorMessage, setSourceErrorMessage] = useState<
    string | undefined
  >('');
  const [questionValue, setQuestionValue] = useState('');
  const [sourceValue, setSourceValue] = useState('');
  const [, setSuccess] = useState(false);
  const [confirmFeedback, setConfirmFeedback] = useState(false);

  useEffect(() => {
    if (questionValue !== '' && !!questionValue.trim()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [questionValue, sourceValue]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setConfirmFeedback(false);
    setQuestionValue('');
    setSourceValue('');
    setBigQuestionLength(false);
    setBigSourceLength(false);
    setSourceErrorMessage('');
    setQuestionErrorMessage('');
  }, []);

  useDisableScrolling(openModal);

  return {
    t,
    setOpenModal,
    confirmFeedback,
    closeModal,
    openModal,
    questionValue,
    bigQuestionLength,
    setQuestionValue,
    questionErrorMessage,
    setSourceValue,
    sourceErrorMessage,
    sourceValue,
    disabled,
    bigSourceLength,
  };
};
