import { ErrorPageContainer } from 'components/common/ErrorPageContainer';
import { useAuthStore } from 'contexts/AuthContext';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const LoginFromKID = observer(() => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isLoading, setIsLoading] = useState(true);
  const [, setError] = useState('');

  useLayoutEffect(() => {
    const checkToken = async () => {
      setIsLoading(true);

      let tokenIsOk: string | Boolean = false;
      if (token) {
        tokenIsOk = await authStore.checkToken(token);
      }

      window.history.replaceState(null, '', window.location.href);

      if (toJS(authStore).isAuth) {
        navigate(`${process.env.REACT_APP_LOGIN_SUCCESS_URL}`, {
          replace: true,
        });
      } else if (tokenIsOk === true) {
        navigate('/register', { replace: true });
      } else {
        setError(tokenIsOk as string);
      }

      setIsLoading(false);
    };

    checkToken();
  }, [token]);

  if (isLoading) return null;

  return (
    <>
      <ErrorPageContainer />
    </>
  );
});
