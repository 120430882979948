import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid';
import { Input } from 'components/shared/Input';
import MultiSelect from 'components/shared/MultiSelect';
import { Notice } from 'components/shared/Notice';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Type } from 'components/shared/Type';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classes from './Business.module.scss';
import { BusinessProps } from './Business.props';
import { useBusiness } from './useBusiness';

export const Business = ({
  defaultValue,
  getParams,
  products,
  onSubmit,
}: BusinessProps) => {
  const {
    t,
    getLink,
    i18n,
    handleSubmit,
    submit,
    control,
    productsOptions,
    selectedProducts,
    callbacks,
    disableSubmit,
    isValid,
    notice,
  } = useBusiness(products, getParams, defaultValue, onSubmit);
  const formRef = useRef(null);
  return (
    <>
      <TitleAndDescription pageName="Account/business" />

      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} size={'xs-bold'} className={classes.title}>
            {t('Business information')}
          </Type>
        </div>
        <p className={classes['info']}>
          {t(
            'By providing us with contact information you further agree on and accept the terms of our'
          )}{' '}
          <Link
            className={classes['info__link']}
            to={getLink(i18n.language)}
            target="_blank"
          >
            {t('Privacy Policy on account')}
          </Link>
        </p>

        <form
          className={classes.inner}
          ref={formRef}
          onSubmit={handleSubmit(submit)}
        >
          <div className={classes.box}>
            <Grid direction={'row'} className={classes.row}>
              <GridItem
                col={{ default: 12 }}
                className={classes['input-field']}
              >
                <div className={classes['item-label']}>{t('Company name')}</div>
                <Controller
                  name="companyName"
                  control={control}
                  render={() => (
                    <Input
                      type={'text'}
                      placeholder={t('Enter company name')}
                      value={defaultValue.companyName}
                      disabled
                      className={classes.input}
                      isAccountForm
                    />
                  )}
                />
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem
                col={{ default: 12 }}
                className={classes['input-field']}
              >
                <div className={classes['item-label']}>
                  {t('Company website')}
                </div>
                <Controller
                  name="url"
                  control={control}
                  render={() => (
                    <Input
                      type={'text'}
                      placeholder={t('Company website')}
                      value={defaultValue.companyLink}
                      disabled
                      className={classes.input}
                      isAccountForm
                    />
                  )}
                />
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes['item-label']}>
                  {t('Office location')}
                </div>
                <Controller
                  name="location"
                  control={control}
                  render={() => (
                    <Input
                      type={'text'}
                      placeholder={t('Office location')}
                      value={defaultValue.companyLocation}
                      disabled
                      className={classes.input}
                      isAccountForm
                    />
                  )}
                />
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes['item-label']}>
                  {t('Company products')}
                </div>
                <Controller
                  name="products"
                  control={control}
                  render={() => (
                    <MultiSelect
                      options={productsOptions}
                      selected={selectedProducts?.map((product) => ({
                        value: product.id,
                        label: product.name,
                      }))}
                      additionalClassName={classes['multi-select']}
                      onSelect={(id) => callbacks.onSelectProduct(Number(id))}
                      onDeleteSelected={(id) =>
                        callbacks.onDeleteSelectedProduct(Number(id))
                      }
                      placeholder={t('What are your company products?')}
                      multiline
                      contentHeight="50px"
                      showCounter
                      isAccountForm
                      isSolutionForm
                    />
                  )}
                />
              </GridItem>
            </Grid>
          </div>

          <div className={classes.submit}>
            <Button
              className={classes.button}
              theme="primary"
              disabled={disableSubmit || !isValid}
              size="middle"
              type="submit"
            >
              {t('Save changes')}
            </Button>
          </div>
        </form>
        {notice.type !== '' && (
          <div className={classes.notice}>
            <Notice type={notice.type}>{notice.text}</Notice>
          </div>
        )}
      </div>
    </>
  );
};
