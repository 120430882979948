import React, { FC } from 'react';
import '../styles/main.scss';
import { useTranslation } from 'react-i18next';
import { AddSolution } from '../containers/AddSolution';

export const AddSolutionPage: FC = () => {
  const { t } = useTranslation();

  return <AddSolution title={t('Adding new Solution')} />;
};
