import api from './http';

export default class Search {
  static async getSearchResults(resource: string, searchTerm: string) {
    return api.get(
      `/api/v1/partners/search/?resource=${encodeURIComponent(
        resource
      )}&search=${encodeURIComponent(searchTerm)}&limit=3`
    );
  }

  static async getFastSearchResults(searchTerm: string) {
    return api.get(
      `/api/v1/partners/search/fast_search/?search=${encodeURIComponent(
        searchTerm
      )}`
    );
  }
}
