import Avatar from 'components/common/Avatar';
import { useTranslation } from 'react-i18next';
import classes from './PersonalManagerCard.module.scss';
import { PersonalManagerCardProps } from './PersonalManagerCard.props';

const PersonalManagerCard = ({ image, name }: PersonalManagerCardProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes.card}>
      <div className={classes.card__top}>
        <Avatar src={image} />
        <div className={classes.card__text}>
          <h4 className={classes.card__text_name}>
            {name || t('Assigning manager')}
          </h4>
          <span className={classes.card__text_desc}>
            {t('Your personal manager')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PersonalManagerCard;
