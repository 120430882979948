import { useUserStore } from 'contexts/UserContext';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { PartnerPage } from './Partner';

function ConfirmModerate() {
  const { user } = useUserStore();

  if (user?.id && !user?.moderated) {
    return <PartnerPage />;
  }

  return <Outlet />;
}

export default ConfirmModerate;
