import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid';
import { CheckIcon } from 'components/shared/Icons/CheckIcon';
import { EyeIcon } from 'components/shared/Icons/EyeIcon';
import { InfoIcon } from 'components/shared/Icons/InfoIcon';
import { KrestIcon } from 'components/shared/Icons/KrestIcon';
import { Input } from 'components/shared/Input';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { Type } from 'components/shared/Type';
import { PASS_MODE } from 'constants/enum';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import classes from './ChangePassword.module.scss';
import { useChangePassword } from './useChangePassword';

export const ChangePassword = () => {
  const {
    showPassword,
    t,
    handleSubmit,
    onSubmit,
    control,
    errors,
    setOldPassword,
    handleShowPassword,
    password,
    numbersCheck,
    symbolsCheck,
    letterCheck,
    setPassword,
    passwordRepeat,
    setPasswordRepeat,
    isValid,
    disableSubmit,
  } = useChangePassword();
  const [showChecks, setShowCheks] = useState(false);
  const [showRepeatCheck, setShowRepeatCheck] = useState(false);

  const { oldPass, newPass, newPassConfirmation } = showPassword;

  return (
    <>
      <TitleAndDescription pageName="Account/change-password" />
      <div className={classes.wrapper}>
        <div className={classes.titleWrap}>
          <Type tag={'h2'} size={'xs-bold'} className={classes.title}>
            {t('Change password')}
          </Type>
        </div>

        <form
          autoComplete="off"
          className={classes.inner}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classes.box}>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  <div className={classes['input-field']}>
                    <div className={classes['item-label']}>
                      {t('Current password')}
                    </div>
                    <Controller
                      name="oldPass"
                      control={control}
                      render={({ field }) => (
                        <Input
                          type={oldPass ? 'text' : 'password'}
                          placeholder={t('Enter password')}
                          value={field.value}
                          error={!!errors.oldPass}
                          onChange={(e) => {
                            field.onChange(e);
                            setOldPassword(e);
                          }}
                          className={clsx(classes.password, classes.input)}
                          isAccountForm
                        />
                      )}
                    />
                    {errors.oldPass && errors.oldPass.type !== 'min' ? (
                      <TooltipContainer
                        text={errors.oldPass?.message}
                        customClasses={'kit-ui-block'}
                        position={'top'}
                        className={classes.error}
                      >
                        <InfoIcon fill="#F5222D" width="18px" height="18px" />
                      </TooltipContainer>
                    ) : (
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => handleShowPassword(PASS_MODE.OLD)}
                        className={classes['show-password']}
                      >
                        <EyeIcon width="18.5px" height="14px" />
                      </div>
                    )}
                  </div>
                </div>
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  {showChecks && (
                    <div className={classes['password-check']}>
                      <div className={classes.title}>
                        {t('Password requirements')}
                      </div>
                      <div className={classes.checks}>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {password.length >= 8 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length > 0 &&
                              password.length < 8 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              password.length >= 8
                                ? classes.correct
                                : password.length > 0 && classes.wrong
                            )}
                          >
                            {t('At least 8 characters long')}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {numbersCheck && password.length >= 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              numbersCheck && password.length >= 1
                                ? classes.correct
                                : password.length >= 1 && classes.wrong
                            )}
                          >
                            {t('Contains numbers')}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {symbolsCheck && password.length >= 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              symbolsCheck &&
                                password.length >= 1 &&
                                classes.correct,
                              !(symbolsCheck && password.length >= 1) &&
                                password.length >= 1 &&
                                classes.wrong
                            )}
                          >
                            {t('Symbols ~!@#$%^&*()_+<>?￥¿¡·॰।')}
                          </div>
                        </div>
                        <div className={classes.check}>
                          <div className={classes.icon}>
                            {password.length === 0 && (
                              <CheckIcon fill="#A09CAC" />
                            )}
                            {letterCheck && password.length >= 1 ? (
                              <CheckIcon fill="#00C853" />
                            ) : (
                              password.length >= 1 && <KrestIcon />
                            )}
                          </div>
                          <div
                            className={clsx(
                              classes.name,
                              letterCheck &&
                                password.length >= 1 &&
                                classes.correct,
                              !(letterCheck && password.length >= 1) &&
                                password.length >= 1 &&
                                classes.wrong
                            )}
                          >
                            {t('Password must contain at least 1 letter')}
                          </div>
                        </div>
                        {(errors?.newPass?.type === 'server' ||
                          errors?.newPass?.message ===
                            t('Password must not contain spaces')) && (
                          <div className={classes.check}>
                            <div className={classes.icon}>
                              <KrestIcon />
                            </div>
                            <div className={clsx(classes.name, classes.wrong)}>
                              {errors.newPass?.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className={classes['item-label']}>
                    {t('New password')}
                  </div>

                  <Controller
                    name="newPass"
                    control={control}
                    render={({ field }) => (
                      <Input
                        type={newPass ? 'text' : 'password'}
                        placeholder={t('Enter new password')}
                        value={field.value}
                        onChange={(e) => {
                          field.onChange(e);
                          setPassword(e);
                        }}
                        error={!!errors.newPass}
                        onBlur={() => {
                          setShowCheks(false);
                        }}
                        onFocus={() => setShowCheks(true)}
                        className={classes.input}
                        isAccountForm
                      />
                    )}
                  />
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handleShowPassword(PASS_MODE.NEW)}
                    onKeyDown={() => {}}
                    className={classes['show-password']}
                  >
                    <EyeIcon width="18px" height="14px" />
                  </div>
                </div>
              </GridItem>
            </Grid>
            <Grid direction={'row'} className={classes.row}>
              <GridItem col={{ default: 12 }}>
                <div className={classes.field}>
                  {showRepeatCheck && (
                    <div className={classes['repeat-check']}>
                      <div className={classes.title}>
                        {t('Password checking')}
                      </div>
                      <div className={classes.check}>
                        <div className={classes.icon}>
                          {passwordRepeat.length === 0 && (
                            <CheckIcon fill="#A09CAC" />
                          )}
                          {!errors.newPassConfirmation &&
                          passwordRepeat.length > 1 ? (
                            <CheckIcon fill="#00C853" />
                          ) : (
                            passwordRepeat.length > 0 && <KrestIcon />
                          )}
                        </div>
                        <div
                          className={clsx(
                            classes.name,
                            !errors.newPassConfirmation &&
                              passwordRepeat.length > 1 &&
                              classes.correct,
                            errors.newPassConfirmation &&
                              passwordRepeat.length > 0 &&
                              classes.wrong
                          )}
                        >
                          {errors.newPassConfirmation
                            ? errors.newPassConfirmation?.message
                            : t('Passwords match')}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes['item-label']}>
                    {t('Repeat password')}
                  </div>

                  <Controller
                    render={({ field }) => (
                      <Input
                        type={newPassConfirmation ? 'text' : 'password'}
                        placeholder={t('Enter password')}
                        value={field.value}
                        onChange={(e) => {
                          setPasswordRepeat(e);
                          field.onChange(e);
                        }}
                        error={!!errors.newPassConfirmation}
                        onBlur={() => setShowRepeatCheck(false)}
                        onFocus={() => setShowRepeatCheck(true)}
                        className={classes.input}
                        isAccountForm
                      />
                    )}
                    name="newPassConfirmation"
                    control={control}
                  />
                  <div
                    role="button"
                    tabIndex={-1}
                    onClick={() => handleShowPassword(PASS_MODE.CONFIRM)}
                    onKeyDown={() => {}}
                    className={classes['show-password']}
                  >
                    <EyeIcon width="18px" height="14px" />
                  </div>
                </div>
              </GridItem>
            </Grid>
          </div>

          <div className={classes.submit}>
            <Button
              className={clsx(classes.button, classes['submit-button'])}
              theme="primary"
              size="middle"
              disabled={!isValid || disableSubmit}
            >
              {t('Save password')}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
