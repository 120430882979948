import classes from "../PartnerContainer.module.scss";
import { Button } from "components/shared/Button";
import { usePartnerStore } from "contexts/PartnerContext";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import UiIcon from "components/shared/Icon";

const EmptyBlock = () => {
  const { clearCopyOfService } = usePartnerStore();
  const { t } = useTranslation();

  return (
    <div className={classes.emptyBlock}>
      <div className={classes.emptyBlock__title}>
        <h1>{t("You have no added Solutions")}</h1>
      </div>

      <div className={classes.emptyBlock__description}>
        <p>{t("Create your first solution and start earning today")}</p>
      </div>

      <div className={classes.emptyBlock__button}>
        <NavLink to="/solutions/add-solution/">
          <Button
            theme="primary"
            className={classes.mainButton}
            onClick={clearCopyOfService}
          >
            <UiIcon name="PlusIcon" additionalClassName={classes.plusIcon} />
            {t("Add solution")}
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default EmptyBlock;
