import { TFunction } from 'i18next';
import classes from './SolutionDetail.module.scss';

export const solutionDetailHints = (t: TFunction) => [
  {
    element: `.${classes['content-wrapper']}`,
    title: t(
      'Keep track of statistics on how clients interact with your solution over different time periods. Analytics is updated on a daily basis.'
    ),
    intro: '',
    position: 'top',
    disableInteraction: true,
    scrollTo: 'tooltip',
  },
  {
    element: `.${classes['hints-block']}`,
    title: t(
      'All the detailed information that clients see on the Platform is available on tabs Main info, Cases and Price. Editing is currently only possible upon request to your personal manager to avoid inaccurate data.'
    ),
    intro: '',
    scrollTo: 'tooltip',
    position: 'right',
    disableInteraction: true,
  },
];
