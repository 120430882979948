import React, { FC, useEffect, useRef, useState } from 'react';
import classes from '../FormBlock/FormBlock.module.scss';
import clsx from 'classnames';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { SelectData } from 'components/shared/SelectComponent';
import UiIcon from 'components/shared/Icon';
import { useTranslation } from 'react-i18next';
import FormInput, { EndIconProps } from '../Input';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { debounce } from 'lodash';

interface SelectProps {
  options?: SelectData[];
  placeholder?: string;
  setSelect?: (val: any) => void;
  select: any;
  title?: string;
  open?: boolean;
  additionalValue?: string;
  setOpen?: (val: boolean) => void;
  defaultValue?: any;
  onClick?: () => void;
  onSelect?: (item: SelectData) => void;
  error?: string;
  className?: string;
  disabled?: boolean;
  isSolutionForm?: boolean;
  endIcon?: EndIconProps;
  isSearch?: boolean;
  searchPlaceholder?: string;
  onSearch?: (value: string) => void;
  debounceCount?: number;
}

export const Select: FC<SelectProps> = ({
  title,
  onClick,
  options,
  select,
  setSelect,
  defaultValue,
  onSelect,
  error,
  className,
  placeholder,
  disabled,
  isSolutionForm,
  endIcon,
  isSearch,
  searchPlaceholder,
  onSearch,
  debounceCount,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  const startIconOptions = {
    startIconName: 'SearchIconAlt',
    startIconClassName: classes.startIcon,
  };

  const selectRef = useRef<HTMLDivElement>(null);
  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = endIcon || {};

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef?.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderDescription = (description: string) => {
    if (description) {
      return <span className={classes.additionalValue}> {description}</span>;
    }
  };

  const callbacks = {
    onSearch: (value: string) => {
      setSearch(value);

      if (debounce) {
        debounce(() => onSearch?.(value), debounceCount)();
      } else {
        onSearch?.(value);
      }
    },
    onExpandChange: (value?: boolean) => {
      if (typeof value === 'boolean') {
        setOpen(value);
      } else {
        setOpen((prev) => !prev);
      }
    },
    onKeyboardExpandChange: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        callbacks.onExpandChange(true);
      }
    },
  };

  return (
    <div className={className}>
      <div
        className={clsx(
          classes.selectBox,
          disabled && classes.selectBox__disabled
        )}
        ref={selectRef}
        onClick={() => onClick && onClick()}
      >
        <div className={classes.wrapper}>
          <div
            onClick={() => {
              if (setOpen) setOpen(!open);
            }}
            className={clsx(
              classes['main-input'],
              open && classes.dropActive,
              error && classes.error,
              isSolutionForm && classes['main-input__solutionForm']
            )}
          >
            <div
              className={clsx(
                classes.inputBlock,
                disabled && classes.inputBlock__disabled
              )}
            >
              <span
                className={clsx(
                  classes.inputTitle,
                  select ? classes.inputTitleSelected : ''
                )}
              >
                {title}
              </span>
              <div className={classes.drop}>
                {select?.name || defaultValue?.name}
                {renderDescription(
                  select?.description || defaultValue?.description
                )}
              </div>
            </div>
            {endIconName && (
              <TooltipContainer
                text={endIconTooltipText}
                customClasses={'kit-ui-block'}
                position={endIconTooltipPosition}
                className={endIconTooltipClassName}
              >
                <UiIcon
                  name={endIconName}
                  additionalClassName={clsx(
                    classes.endIcon,
                    endIconClassName,
                    error && classes.errorIcon
                  )}
                />
              </TooltipContainer>
            )}
            <div className={clsx(classes.arrow, open && classes.arrowActive)}>
              <ArrowDownIcon />
            </div>
          </div>
          {open && (
            <div className={classes.dropdown}>
              {isSearch ? (
                <div
                  className={clsx(
                    classes['enter-source'],
                    isSolutionForm && classes['enter-source__form']
                  )}
                >
                  <FormInput
                    value={search}
                    placeholder={searchPlaceholder || ''}
                    onChange={callbacks.onSearch}
                    startIcon={startIconOptions}
                    classNames={classes['enter-source__input']}
                    isSearch
                  />
                  <div className={classes.list}>
                    {options &&
                      options?.map((item: SelectData, index: number) => (
                        <div
                          key={`${item.id}${index}`}
                          onClick={(e) => {
                            if (setOpen) setOpen(false);
                            if (item?.id !== select?.id) {
                              if (onSelect) onSelect(item);
                              if (setSelect) setSelect(item);
                            }
                          }}
                          className={clsx(
                            classes.item,
                            item?.id === select?.id && classes.active
                          )}
                        >
                          <span>{item?.name}</span>
                          {item?.description && (
                            <span className={classes.additionalValue}>
                              {item?.description}
                            </span>
                          )}
                          {item?.id === select?.id && !isSolutionForm && (
                            <UiIcon
                              name="CheckIcon"
                              additionalClassName={classes.checkIcon}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div className={classes.list}>
                  {options &&
                    options?.map((item: SelectData, index: number) => (
                      <div
                        key={`${item.id}${index}`}
                        onClick={(e) => {
                          if (setOpen) setOpen(false);
                          if (item?.id !== select?.id) {
                            if (onSelect) onSelect(item);
                            if (setSelect) setSelect(item);
                          }
                        }}
                        className={clsx(
                          classes.item,
                          item?.id === select?.id && classes.active
                        )}
                      >
                        <span>{item?.name}</span>
                        {item?.description && (
                          <span className={classes.additionalValue}>
                            {item?.description}
                          </span>
                        )}
                        {item?.id === select?.id && !isSolutionForm && (
                          <UiIcon
                            name="CheckIcon"
                            additionalClassName={classes.checkIcon}
                          />
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {error && (
        <div className={classes.errorText}>
          {error || t('Fill in the field')}
        </div>
      )}
    </div>
  );
};
