import * as React from 'react';
import { SVGProps } from 'react';
import { RUSSIAN_LOCALE } from 'utils';

export const WalletGradientIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.1875 2.1875H2.8125C2.4668 2.1875 2.1875 2.4668 2.1875 2.8125V17.1875C2.1875 17.5332 2.4668 17.8125 2.8125 17.8125H17.1875C17.5332 17.8125 17.8125 17.5332 17.8125 17.1875V2.8125C17.8125 2.4668 17.5332 2.1875 17.1875 2.1875ZM16.4062 11.25H10.3125V8.75H16.4062V11.25ZM16.4062 16.4062H3.59375V3.59375H16.4062V7.5H9.6875C9.3418 7.5 9.0625 7.7793 9.0625 8.125V11.875C9.0625 12.2207 9.3418 12.5 9.6875 12.5H16.4062V16.4062ZM11.3281 10C11.3281 10.2072 11.4104 10.4059 11.5569 10.5524C11.7035 10.6989 11.9022 10.7812 12.1094 10.7812C12.3166 10.7812 12.5153 10.6989 12.6618 10.5524C12.8083 10.4059 12.8906 10.2072 12.8906 10C12.8906 9.7928 12.8083 9.59409 12.6618 9.44757C12.5153 9.30106 12.3166 9.21875 12.1094 9.21875C11.9022 9.21875 11.7035 9.30106 11.5569 9.44757C11.4104 9.59409 11.3281 9.7928 11.3281 10Z"
      fill="url(#paint0_linear_3263_27403)"
    />
    <defs>
      {RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_3263_27403"
          x1="-6.46875"
          y1="-7.78125"
          x2="13.9687"
          y2="14.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB3D2" />
          <stop offset="1" stopColor="#FF4848" />
        </linearGradient>
      )}
      {!RUSSIAN_LOCALE && (
        <linearGradient
          id="paint0_linear_3263_27403"
          x1="0.560002"
          y1="2.32"
          x2="18.88"
          y2="23.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E3CFF" />
          <stop offset="1" stopColor="#4BA5FF" />
        </linearGradient>
      )}
    </defs>
  </svg>
);
