import { TFunction } from 'i18next';
import classes from './Account.module.scss';
import managerClasses from './PersonalManagerCard/PersonalManagerCard.module.scss';

export const accountHints = (t: TFunction) => [
  {
    element: `.${classes.innerBlock}`,
    title: t(
      'Your personal information filled in during registration is displayed here. Email cannot be changed, as the account is linked to this identificator'
    ),
    intro: '',
    position: 'right',
    scrollTo: 'tooltip',
  },
  {
    element: `.${classes.innerBlock}`,
    title: t(
      'Your company information is available for editing upon request to your personal manager'
    ),
    intro: '',
    position: 'right',
    scrollTo: 'tooltip',
  },
  {
    element: `.${classes.innerBlock}`,
    title: t('You can set a new password here'),
    intro: '',
    position: 'right',
    scrollTo: 'tooltip',
  },
  {
    element: `.${managerClasses.card}`,
    title: t(
      'Your manager will help you in signing a contract, creating solutions and interacting with projects'
    ),
    intro: '',
    position: 'bottom',
    scrollTo: 'tooltip',
  },
];
