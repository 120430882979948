import { API_URL } from 'services/http';
import classes from './Avatar.module.scss';
import { RUSSIAN_LOCALE } from 'utils';
import manager_ru from '../../../assets/img/default_manager_image_ru.svg';

interface AvatarProps {
  src?: string;
  alt?: string;
  style?: React.CSSProperties;
}

const Avatar = (props: AvatarProps) => (
  <img
    style={props.style}
    alt={props.alt || ''}
    src={
      props.src ||
      (RUSSIAN_LOCALE
        ? manager_ru
        : `${API_URL}/static/core/img/default_manager_image.png`)
    }
    className={classes.avatar}
  />
);

export default Avatar;
