import clsx from 'clsx';
import { User } from 'components/common/User';
import { FinancesIcon } from 'components/shared/Icons/FinancesIcon';
import { MarketplaceIcon } from 'components/shared/Icons/MarketplaceIcon';
import { ProjectIcon } from 'components/shared/Icons/ProjectIcon';
import { routers } from 'constants/routers';
import { useUserStore } from 'contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import classes from '../Header.module.scss';
import { HeaderButtonsProps } from './HeaderButtons.props';

const HeaderButtons = ({
  bottomMenuSize,
  disableHeader,
  path,
  allowLogout,
  userName,
  isSuccessLocalPreferences,
  isActiveLink,
}: HeaderButtonsProps) => {
  const { t } = useTranslation();
  const userStore = useUserStore();

  return (
    <div
      style={{
        zIndex: 102,
        height: bottomMenuSize,
      }}
      className={clsx(
        classes['header__bottom-menu'],
        classes['bottom-menu'],
        disableHeader && classes.disabled
      )}
    >
      <ul className={clsx(classes['bottom-menu__list'])}>
        <li>
          <NavLink
            to={routers.solutions}
            className={(navData) =>
              clsx(
                classes['bottom-menu__item'],
                classes.linkWrap,
                navData.isActive && classes.active
              )
            }
          >
            <MarketplaceIcon />
            <div className={classes['bottom-menu__item-text']}>
              {t('Solutions')}
            </div>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={routers.projects}
            className={(navData) =>
              clsx(
                classes['bottom-menu__item'],
                navData.isActive && classes.active
              )
            }
          >
            <div className={classes['header__counter']}>
              <ProjectIcon />
            </div>
            <div className={classes['bottom-menu__item-text']}>
              {t('Projects')}
            </div>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={routers.finances}
            className={(navData) =>
              clsx(
                classes['bottom-menu__item'],
                classes.linkWrap,
                navData.isActive && classes.active
              )
            }
          >
            <FinancesIcon />
            <div className={classes['bottom-menu__item-text']}>
              {t('Finances')}
            </div>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={path}
            className={clsx(
              classes['bottom-menu__item'],
              classes['bottom-menu__item--user'],
              isActiveLink && classes.active,
              (!isSuccessLocalPreferences && !allowLogout) ||
                (!userStore?.user?.email_confirmed && classes.disabled)
            )}
          >
            <User name={userName} />
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default HeaderButtons;
