import { TFunction } from 'i18next';
import classes from './ProjectsDetail.module.scss';

export const projectsDetailHints = (t: TFunction) => [
  {
    element: `.${classes.grid}`,
    title: t(
      'You will be able to see the actual negotiation stage and the manager leading the client deal'
    ),
    intro: '',
    position: 'top',
    scrollTo: 'tooltip',
  },
];
