import clsx from 'clsx';
import classes from './MultiSelect.module.scss';
import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CheckboxSelectItem from './CheckboxSelectItem';
import { ArrowDownIcon } from 'components/shared/Icons/ArrowDownIcon';
import { debounce } from 'lodash';
import SelectedPreview from './SelectedPreview';
import FormInput from 'containers/AddSolution/Input';
import { Input } from '../Input';
import { scrollNearBottom } from 'tools/scrollNearBottom';
import Lottie from 'lottie-react';
import loader from 'components/shared/PageLoader/loader.json';
import { useTranslation } from 'react-i18next';
import { TooltipContainer } from '../Tooltip/TooltipContainer';
import UiIcon from 'components/shared/Icon';
import { EndIconProps } from 'containers/AddSolution/Input';
import { RUSSIAN_LOCALE } from 'utils';

export type MultiSelectOption = {
  value: string | number;
  label: React.ReactNode;
  order?: number;
};

type MultiSelectProps = {
  isSearch?: boolean;
  isLoading?: boolean;
  selected?: MultiSelectOption[];
  options: MultiSelectOption[];
  placeholder?: string;
  debounce?: number;
  scrollDebounce?: number;
  title?: string;
  additionalClassName?: string;
  endIcon?: EndIconProps;
  error?: string;
  searchPlaceholder?: string;
  isSolutionForm?: boolean;
  isAccountForm?: boolean;
  multiline?: boolean;
  contentHeight?: string;
  contentWidth?: string;
  showCounter?: boolean;
  withOrder?: boolean;
  onScrollBottom?: () => void;
  onSearch?: (val: string) => void;
  onSelect?: (id: string | number) => void;
  onDeleteSelected?: (id: string | number) => void;
  mainInputClassName?: string;
  mainInputExpandedClassName?: string;
  fakeInputClassName?: string;
  focusClassName?: string;
};

function MultiSelect({
  isSearch,
  placeholder = 'Select items',
  error,
  ...props
}: MultiSelectProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [itemHovered, setItemHovered] = useState<string | number | null>(null);
  const [selectedtOverflow, setSelectedOverflow] = useState(false);
  const [isOutside, setIsOutside] = useState<boolean[]>([]);
  const [showLessActive, setShowLessActive] = useState(false);
  const [distanceToRightEdge, setDistanceToRightEdge] = useState<number>(0);
  const showCounterFromLeft = distanceToRightEdge < 40;

  const selectorRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const previewItemsRef = useRef<HTMLDivElement[]>([]);
  const selectorContentRef = useRef<HTMLDivElement | null>(null);
  const optionsListRef = useRef<HTMLDivElement | null>(null);

  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = props?.endIcon || {};

  useLayoutEffect(() => {
    const checkBounds: () => void = () => {
      if (!selectorContentRef?.current || !previewItemsRef?.current?.length)
        return;

      if (props.selected && props.selected.length) {
        const parentRect = selectorContentRef.current.getBoundingClientRect();
        const tempOutsideStatus: boolean[] = [];

        previewItemsRef.current.forEach((item, index) => {
          const childRect = item.getBoundingClientRect();

          //округляем координаты до целого на случай если масштаб страницы отличается от 100%
          const childTop = Math.round(childRect.top);
          const childLeft = Math.round(childRect.left);
          const childBottom = Math.round(childRect.bottom);
          const childRight = Math.round(childRect.right);

          const parentTop = Math.round(parentRect.top);
          const parentLeft = Math.round(parentRect.left);
          const parentBottom = Math.round(parentRect.bottom);
          const parentRight = Math.round(parentRect.right);

          const outside =
            childTop < parentTop ||
            childLeft < parentLeft ||
            childBottom > parentBottom ||
            childRight > parentRight;

          if (props.selected?.[index]) {
            tempOutsideStatus.push(outside);
          }
        });

        if (tempOutsideStatus.includes(true)) {
          setSelectedOverflow(true);
        } else {
          setSelectedOverflow(false);
        }

        const lastVisibleIndex = tempOutsideStatus.lastIndexOf(false);
        const lastVisibleItem = previewItemsRef.current[lastVisibleIndex];

        if (lastVisibleItem) {
          const lastVisibleRect = lastVisibleItem.getBoundingClientRect();

          const distanceToRightEdge = parentRect.right - lastVisibleRect.right;
          setDistanceToRightEdge(distanceToRightEdge);
        } else {
          setDistanceToRightEdge(0);
        }

        setIsOutside(tempOutsideStatus);
      }
    };

    checkBounds();
  }, [props.selected?.length, showLessActive]);

  const outsideCount = useMemo(
    () => isOutside.reduce((acc, curr) => (curr ? acc + 1 : acc), 0),
    [isOutside]
  );

  const selectedAsObject = props.selected?.reduce(
    (acc, curr) => {
      acc[curr.value] = curr.label;
      return acc;
    },
    {} as Record<string, React.ReactNode>
  );

  const callbacks = {
    onListScroll: () => {
      const list = optionsListRef.current;
      if (list) {
        if (scrollNearBottom(list)) {
          debounce(() => {
            props.onScrollBottom?.();
          }, props.scrollDebounce)();
        }
      }
    },
    onSearch: (value: string) => {
      setSearch(value);

      if (props.debounce) {
        debounce(() => props.onSearch?.(value), props.debounce)();
      } else {
        props.onSearch?.(value);
      }

      setItemHovered(null);
    },
    onExpandChange: (value?: boolean) => {
      if (typeof value === 'boolean') {
        setExpanded(value);
      } else {
        setExpanded((prev) => !prev);
      }
      setItemHovered(null);
    },
    onSelectChange: (id: string | number) => {
      if (id === 'all' && props.selected?.length) {
        props.onDeleteSelected?.(id);
        return;
      }
      if (selectedAsObject?.[id]) {
        props.onDeleteSelected?.(id);
        return;
      }
      props.onSelect?.(id);
    },
    onKeyboardExpandChange: (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        callbacks.onExpandChange(true);
      }
    },
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        (e.key === 'ArrowDown' || e.key === 'ArrowUp') &&
        props.options.length
      ) {
        e.stopPropagation();
        if (e.key === 'ArrowDown' && !itemHovered) {
          setItemHovered(props.options[0].value);
          return;
        }

        if (e.key === 'ArrowUp' && !itemHovered) {
          setItemHovered(props.options.at(-1)?.value || null);
          itemsRef.current[props.options.length - 1].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          return;
        }

        const currentItemIndex = props.options.findIndex(
          (option) => option.value === itemHovered
        );
        const nextItemIndex =
          e.key === 'ArrowDown' ? currentItemIndex + 1 : currentItemIndex - 1;
        const nextItem = props.options[nextItemIndex];

        if (nextItem) {
          setItemHovered(nextItem.value);
          itemsRef.current[nextItemIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
          return;
        }
      }

      if (e.key === 'Escape') callbacks.onExpandChange(false);
      if (e.key === 'Enter' && itemHovered)
        callbacks.onSelectChange(itemHovered);
    },
  };

  useEffect(() => {
    const onClickOutSide = function (event: Event) {
      if (
        selectorRef.current &&
        !selectorRef.current.contains(event.target as Node)
      ) {
        callbacks.onExpandChange(false);
      }
    };

    document.addEventListener('click', onClickOutSide);

    return () => document.removeEventListener('click', onClickOutSide);
  }, [selectorRef, callbacks.onExpandChange]);

  const title = props?.title && (
    <div className={classes.inputBlock}>
      <span
        className={clsx(
          classes.inputTitle,
          props.selected?.length ? classes.inputTitleSelected : ''
        )}
      >
        {props.title}
      </span>
    </div>
  );
  props?.options?.forEach((item) => {
    props.selected?.forEach((selectedItem) => {
      if (selectedItem.value === item.value) {
        item.order = selectedItem.order;
      }
    });
  });

  const checkboxItems = props.options.map((item, index: number) => (
    <div
      key={`${item.value}-${index}`}
      onMouseEnter={() => setItemHovered(item.value)}
      ref={(e) => {
        if (e) {
          itemsRef.current[index] = e;
        }
      }}
    >
      <CheckboxSelectItem
        checked={!!selectedAsObject?.[item.value]}
        onClick={() => callbacks.onSelectChange(item.value)}
        hovered={item.value === itemHovered}
        label={item.label}
        order={item?.order && item.order > 0 ? item.order : undefined}
        withOrder={props.withOrder}
      />
    </div>
  ));

  const emptyState = (
    <div className={classes.emptyState}>
      <UiIcon
        name="EmptyIcon"
        additionalClassName={classes['emptyState__icon']}
      />
      <span className={classes['emptyState__text']}>
        {t('Nothing found matching your request')}
      </span>
    </div>
  );

  const startIconOptions = {
    startIconName: 'SearchIconAlt',
    startIconClassName: classes.startIcon,
  };

  return (
    <div
      ref={selectorRef}
      className={clsx(
        classes.wrapper,
        error && classes.errorWrapper,
        props?.additionalClassName
      )}
    >
      <div
        onClick={() => callbacks.onExpandChange()}
        tabIndex={0}
        onKeyUp={callbacks.onKeyboardExpandChange}
        className={clsx(
          classes['main-input'],
          title && classes['main-input__withTitle'],
          title && expanded && classes['main-input__active'],
          error && classes.error,
          props?.isSolutionForm &&
            endIconName &&
            classes['main-input__solutionForm'],
          props?.isAccountForm && classes['main-input__accountForm'],
          expanded && props.mainInputExpandedClassName,
          props.mainInputClassName
        )}
      >
        <div className={classes.titleBlock}>
          {title}
          <div
            style={{
              maxHeight: showLessActive ? 'unset' : props.contentHeight,
              width: props.contentWidth,
            }}
            ref={selectorContentRef}
            className={clsx(
              classes['main-input--content'],
              props.multiline && classes.multiline
            )}
          >
            {props.selected?.map((item, index) => (
              <React.Fragment key={`preview-${item.value}-${index}`}>
                {props.showCounter &&
                  selectedtOverflow &&
                  !showLessActive &&
                  !isOutside[index] &&
                  isOutside[index + 1] &&
                  showCounterFromLeft && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (props.multiline) {
                          setShowLessActive(true);
                        }
                      }}
                      className={classes.outside__tag}
                    >
                      +{showCounterFromLeft ? outsideCount + 1 : outsideCount}
                    </button>
                  )}
                <SelectedPreview
                  isOutside={
                    isOutside[index] ||
                    (props.showCounter &&
                      !isOutside[index] &&
                      isOutside[index + 1] &&
                      showCounterFromLeft)
                  }
                  shouldShrink={
                    !props.isSolutionForm && !!index && !props.multiline
                  }
                  ref={(e) => {
                    if (e) {
                      previewItemsRef.current[index] = e;
                    }
                  }}
                  onDelete={() => {
                    props.onDeleteSelected?.(item.value);
                  }}
                  label={item.label}
                  order={item?.order}
                />
                {props.showCounter &&
                  selectedtOverflow &&
                  !showLessActive &&
                  !isOutside[index] &&
                  isOutside[index + 1] &&
                  !showCounterFromLeft && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        if (props.multiline) {
                          setShowLessActive(true);
                        }
                      }}
                      className={clsx(
                        classes.outside__tag,
                        RUSSIAN_LOCALE && classes.outside__tag__ru
                      )}
                    >
                      {RUSSIAN_LOCALE ? (
                        <span className={classes.outside__tag__ru__plus}>
                          +
                        </span>
                      ) : (
                        '+'
                      )}
                      {outsideCount}
                    </button>
                  )}
              </React.Fragment>
            ))}

            {!props.selected?.length && (
              <span className={classes['main-input--placeholder']}>
                {placeholder}
              </span>
            )}
            {props.showCounter &&
              props.multiline &&
              showLessActive &&
              selectorContentRef?.current &&
              selectorContentRef?.current?.clientHeight > 50 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowLessActive(false);
                  }}
                  className={classes.outside__tag}
                >
                  {t('See less')}
                </button>
              )}
          </div>
        </div>
        {endIconName && (
          <TooltipContainer
            text={endIconTooltipText}
            customClasses={'kit-ui-block'}
            position={endIconTooltipPosition}
            className={endIconTooltipClassName}
          >
            <UiIcon
              name={endIconName}
              additionalClassName={clsx(
                classes.endIcon,
                endIconClassName,
                error && classes.errorIcon
              )}
            />
          </TooltipContainer>
        )}
        <div className={clsx(classes.arrow, expanded && classes.arrowActive)}>
          <ArrowDownIcon />
        </div>
      </div>
      {expanded && (
        <div className={classes.dropdown}>
          {isSearch ? (
            <div
              className={clsx(
                classes['enter-source'],
                props.isSolutionForm && classes['enter-source__form']
              )}
            >
              {!props.isSolutionForm ? (
                <Input
                  value={search}
                  autoFocus
                  onKeyDown={callbacks.onKeyDown}
                  onChange={callbacks.onSearch}
                />
              ) : (
                <FormInput
                  value={search}
                  placeholder={props?.searchPlaceholder || ''}
                  onKeyDown={callbacks.onKeyDown}
                  onChange={callbacks.onSearch}
                  startIcon={startIconOptions}
                  classNames={classes['enter-source__input']}
                  focusClassName={props.focusClassName}
                  isSearch
                />
              )}
            </div>
          ) : (
            <input
              className={clsx(classes['fake-input'], props.fakeInputClassName)}
              autoFocus
              onKeyUp={callbacks.onKeyDown}
            />
          )}
          <div
            className={clsx(
              classes.list,
              props?.isSolutionForm && classes['list__solutionForm']
            )}
            ref={optionsListRef}
            onScroll={callbacks.onListScroll}
          >
            {!search && isSearch ? (
              <div className={classes.list__checkAll}>
                <div
                  className={classes.list__checkAll__allItem}
                  onMouseEnter={() => setItemHovered('all')}
                >
                  <CheckboxSelectItem
                    withOrder={props.withOrder}
                    hovered={'all' === itemHovered}
                    label={t('All')}
                    checked={props.selected?.length === props.options?.length}
                    onClick={() => callbacks.onSelectChange('all')}
                    indeterminate={
                      props.selected &&
                      props.selected?.length > 0 &&
                      props.selected?.length < props.options?.length
                    }
                  />
                </div>
                <div className={classes.list__checkAll__items}>
                  {checkboxItems}
                </div>
              </div>
            ) : checkboxItems?.length ? (
              checkboxItems
            ) : (
              emptyState
            )}
            {props.isLoading && (
              <div className={classes.loaderWrapper}>
                <Lottie animationData={loader} />
              </div>
            )}
          </div>
        </div>
      )}
      {error && (
        <div className={classes.errorText}>
          {error || t('Fill in the field')}
        </div>
      )}
    </div>
  );
}

export default memo(MultiSelect);
