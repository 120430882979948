import { TInvoice } from '@type/finance';
import clsx from 'clsx';
import PageTabs from 'components/common/PageTabs';
import EmptyProducts from 'components/shared/EmptyProducts/EmptyProducts';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { financesHints, LABELS } from 'constants/onboarding';
import { useUserStore } from 'contexts/UserContext';
import { Steps } from 'intro.js-react';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { useWindowWidth } from '../../hooks/useWindowWidth';
import 'styles/helpers/introTooltip.scss';
import classes from './Finances.module.scss';
import DocumentsCard from './InfoCards/DocumentsCard';
import LegalEntityCard from './InfoCards/LegalEntityCard';
import TotalCard from './InfoCards/TotalCard';
import InvoicesList from './InvoicesList';
import InvoicesTable from './InvoicesTable';
import PaymentModal from './PaymentModal';
import { columnsArray, MOCK_RESPONSE, tableData } from './invoiceData';

type FinancesTabs = {
  id: 'in' | 'out';
  label: React.ReactNode;
};

export const FinancesContainer = observer(() => {
  const { t } = useTranslation();
  const { isSmallLaptop: isLaptop } = useWindowWidth();
  const isMobile = useWindowWidth().isMediaTablet;
  const userStore = useUserStore();
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.finances);
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ finances: true });
    userStore.enableOnboarding(true);
  }, [userStore.enableOnboarding]);

  const tabs: FinancesTabs[] = [
    {
      id: 'in',
      label: (
        <p className={classes.tab}>
          {t('Incoming')}{' '}
          {MOCK_RESPONSE.results.count_detailed?.incoming && (
            <span>{MOCK_RESPONSE.results.count_detailed?.incoming}</span>
          )}
        </p>
      ),
    },
    {
      id: 'out',
      label: (
        <p className={classes.tab}>
          {t('Outcoming')}{' '}
          {MOCK_RESPONSE.results.count_detailed?.outcoming && (
            <span>{MOCK_RESPONSE.results.count_detailed?.outcoming}</span>
          )}
        </p>
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState<FinancesTabs['id']>('in');
  const [tableActive, setTableActive] = useState(false);
  const [customPageCount, setCustomPageCount] = useState<number | undefined>(5);

  const [openPayModal, setOpenPayModal] = useState(false);

  const columns = useMemo(
    () => columnsArray(t, activeTab === 'in'),
    [t, activeTab]
  );

  const invoices: TInvoice[] = [];

  return (
    <>
      <Steps
        enabled={isLaptop && enableHints}
        steps={financesHints(t)}
        options={{
          tooltipClass: clsx(
            'customTooltip',
            classes.hintsTooltip,
            classes['hintsTooltip__top--center']
          ),
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
          showBullets: false,
          autoPosition: false,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      <TitleAndDescription pageName="Finances" />

      <div className={classes.wrapper}>
        <div className={classes.title}>
          {t('Finances')}
          <span>{0}</span>
        </div>

        <div className={classes['content-wrapper']}>
          <div className={classes.flex}>
            <PageTabs
              activeTab={activeTab}
              tabs={tabs}
              onChange={(tabId) => setActiveTab(tabId)}
            />
          </div>

          <div className={classes['info-wrapper']}>
            <LegalEntityCard
              title={t('Legal entity')}
              companyName={MOCK_RESPONSE.legal_entity.name}
              entityId={''}
              kitBank={{
                label: t('KIT Global bank details'),
                number: MOCK_RESPONSE.legal_entity.kit_global_bank_details,
              }}
              entityBank={{
                label: t('Legal entity bank details'),
                number: MOCK_RESPONSE.legal_entity.legal_entity_bank_details,
              }}
            />

            <TotalCard
              totalDue={0}
              invoicesCount={0}
              onPaymentClick={() => setOpenPayModal(true)}
            />

            <DocumentsCard documents={[]} isMobile={isMobile} />
          </div>

          <div className={classes['invoices-wrapper']}>
            {invoices.length > 0 ? (
              isMobile ? (
                <InvoicesList
                  invoices={invoices.slice(0, 5)}
                  pageCount={customPageCount || 0}
                  handlePaymentClick={() => setOpenPayModal(true)}
                  handleGlobalSearch={(val) => console.log(val)}
                  handleFilterAction={(val) => console.log(val)}
                  handlePeriodChange={(start, end) => console.log(start, end)}
                  handleSort={(val) => console.log(val)}
                />
              ) : (
                <InvoicesTable
                  data={tableData(invoices.slice(0, 5), t)}
                  columns={columns}
                  tableActive={tableActive}
                  pageCount={customPageCount || 0}
                  getDataByPage={() => {}}
                  incomeMode={activeTab === 'in'}
                  handlePaymentClick={() => setOpenPayModal(true)}
                  handleGlobalSearch={(val) => console.log(val)}
                  handleFilterAction={(val) => console.log(val)}
                  handlePeriodChange={(start, end) => console.log(start, end)}
                  handleSort={(val) => console.log(val)}
                />
              )
            ) : (
              <div className={classes.empty}>
                <EmptyProducts
                  additionalClass={classes.empty__content}
                  bottomText={t("You don't have any invoices")}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <PaymentModal
        title="You have invoice for $120 000"
        isMobile={isMobile}
        open={openPayModal}
        infoTitle={t('Bank transfer')}
        infoDesc={t('Pay from your bank account using details in the invoice')}
        infoDownloadText={t('Download invoice')}
        onClose={() => setOpenPayModal(false)}
      />
    </>
  );
});
