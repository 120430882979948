import { useUserStore } from 'contexts/UserContext';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { FormValidationProps } from '../FormBlock.props';
import { usePartnerStore } from 'contexts/PartnerContext';

export const useSecondStep = (
  defaultValues: any,
  setCurrentStep?: (step: number) => void,
  disabled?: boolean,
  setOpenCasesModal?: (value: boolean) => void,
  openCasesModal?: boolean
) => {
  const userStore = useUserStore();

  const { control, trigger, clearErrors } =
    useFormContext<FormValidationProps>();
  const [exampleImgLoaded, setExampleImgLoaded] = useState(false);
  const enableHints =
    isOnboardingActive(userStore?.pagesToOnboard?.solutionCases) &&
    !disabled &&
    exampleImgLoaded;

  const { isCompletedFormSteps } = usePartnerStore();

  const handleExit = useCallback(async () => {
    if (!disabled) {
      setOnboardingOptions({ solutionCases: true });
      await userStore.enableOnboarding(true);
    }
  }, [disabled, userStore]);

  const handleExampleLoaded = function () {
    setExampleImgLoaded(true);
  };

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'cases',
  });

  useEffect(() => {
    if (defaultValues?.length) {
      replace(defaultValues);
    }
  }, []);

  const newCase = {
    caseName: '',
    caseBusinessType: '',
    platforms: [],
    keyAchievements: '',
    secondStepDescription: '',
    kpi: [
      {
        name: '',
        value_type: '',
        value: '',
        unit: '',
      },
    ],
    id: Date.now(),
  };

  const addStep = (e: any) => {
    e.preventDefault();
    clearErrors('cases');
    const targetElement = document.getElementById('emptyCaseId');
    if (targetElement) {
      const elementRect = targetElement.getBoundingClientRect();
      const offset = 20;

      const elementPosition =
        elementRect.top +
        window.scrollY -
        (window.innerHeight - elementRect.height - offset);

      window.scrollTo({ top: elementPosition, behavior: 'smooth' });
    }

    append(newCase);
  };

  const deleteStep = (index: number) => {
    remove(index);
  };

  const onValidateClick = async () => {
    if (trigger) {
      const isValid = await trigger();

      if (fields.length === 0 && setOpenCasesModal) {
        setOpenCasesModal(true);
        return;
      }

      if (isValid && setCurrentStep) {
        setCurrentStep(3);
        isCompletedFormSteps.stepTwo = true;
        return window?.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  return {
    fields,
    enableHints,
    handleExit,
    deleteStep,
    handleExampleLoaded,
    addStep,
    onValidateClick,
    setOpenCasesModal,
    openCasesModal,
  };
};
