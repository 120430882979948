import React, {
  useEffect,
  useRef,
  useState,
  ChangeEvent,
  FocusEvent,
} from 'react';
import { Label } from '../../../components/shared/Input/Label';
import classes from './Input.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { TooltipContainer } from '../../../components/shared/Tooltip/TooltipContainer';
import UiIcon from '../../../components/shared/Icon';

interface EndIconProps {
  endIconName?: string;
  endIconTooltipText?: string;
  endIconTooltipPosition?: string;
  endIconTooltipClassName?: string;
  endIconClassName?: string;
}

interface TextareaProps {
  label?: string;
  labelPosition?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  maxLength?: number;
  additionalClassName?: string;
  errorText?: string;
  errorClassName?: string;
  endIcon?: EndIconProps;
  title?: string;
}

export const Textarea: React.FC<TextareaProps> = ({
  label,
  labelPosition = 'top',
  placeholder,
  disabled,
  error,
  value,
  onChange,
  onBlur,
  onFocus,
  maxLength,
  additionalClassName,
  errorText,
  errorClassName,
  endIcon,
  title = 'title',
}) => {
  const {
    endIconName,
    endIconTooltipText,
    endIconTooltipPosition,
    endIconTooltipClassName,
    endIconClassName,
  } = endIcon || {};
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;
  const formattedValue =
    value && htmlTagPattern.test(value)
      ? value.replace(/<\/?[^>]+>/gi, '').replace(/&mdash;/gi, '—')
      : value;

  return (
    <div className={clsx(classes.textareaWrap, errorClassName)}>
      {label && <Label position={labelPosition}>{label}</Label>}
      <div
        className={clsx(
          classes.textarea,
          focused && classes.focused,
          error && classes.textareaError,
          additionalClassName
        )}
      >
        <div className={classes.inputBlock}>
          <span className={classes.inputTitle}>
            {(focused || value) && title}
          </span>
          <textarea
            className={classes.textareaInput}
            value={formattedValue || ''}
            placeholder={!focused ? placeholder : ''}
            disabled={disabled}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              onChange && onChange(e.target.value)
            }
            onFocus={() => {
              onFocus && onFocus();
              setFocused(true);
            }}
            onBlur={() => {
              onBlur && onBlur();
              setFocused(false);
            }}
            maxLength={maxLength}
            ref={inputRef}
          />
        </div>
        {endIconName && (
          <TooltipContainer
            text={endIconTooltipText}
            customClasses={'kit-ui-block'}
            position={endIconTooltipPosition}
            className={endIconTooltipClassName}
          >
            <UiIcon
              name={endIconName}
              additionalClassName={clsx(
                classes.endIcon,
                endIconClassName,
                error && classes.error
              )}
            />
          </TooltipContainer>
        )}
      </div>
      {error && errorText && (
        <div className={classes.errorText}>
          {errorText || t('Fill in the field')}
        </div>
      )}
    </div>
  );
};
