import clsx from 'classnames';
import MultiSelect from 'components/shared/MultiSelect';
import { SelectData } from 'components/shared/SelectComponent';
import {
  addSolutionsSecondStepHintsSecond,
  LABELS,
} from 'constants/onboarding';
import { Steps } from 'intro.js-react';
import { Controller } from 'react-hook-form';
import Input from '../Input';
import { Textarea } from '../Input/TextArea';
import { Select } from '../Select';
import { multiFieldValues, writeRef } from '../utils';
import classes from './AddCaseForm.module.scss';
import { AddCaseFormProps } from './AddCaseForm.props';
import { KPIFormContainer } from './KPIForm';
import { useAddCaseForm } from './useAddCaseForm';
import { useCommonStore } from 'contexts/CommonContext';
import { addCaseMessages } from '../FormBlock/validationSchemas';

export const AddCaseForm = ({
  caseItem,
  disabled,
  caseNumber = 1,
  onRemoveCase,
  platforms,
  fieldRefs,
  currentStep,
}: AddCaseFormProps) => {
  const businessTypes = useCommonStore().dictionaries?.business_types || [];
  const {
    isLaptop,
    enableHints,
    t,
    i18n,
    handleExit,
    generalCount,
    endIconOptions,
    errors,
    control,
    setSelectBusinessTypes,
    selectPlatforms,
    callbacks,
    formattedValue,
    internalPlatforms,
    internalBusinessTypes,
    setInternalBusinessTypes,
    clearErrors,
  } = useAddCaseForm(
    caseNumber,
    disabled,
    platforms,
    businessTypes,
    currentStep,
    fieldRefs
  );

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  return (
    <div className={classes.caseWrapper}>
      <Steps
        enabled={isLaptop && enableHints}
        steps={addSolutionsSecondStepHintsSecond(t)}
        options={{
          tooltipClass: clsx('customTooltip', classes.hintsTooltip),
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      <div className={clsx(classes.hint, classes['hint--1'])}>
        <div className={clsx(classes.title)}>
          <div className={classes.title__withCounter}>
            <span>{`${t('Case')} ${caseNumber + 1}`}</span>
            <span className={classes.counter}>
              {generalCount} {t('out of 5 filled')}
            </span>
          </div>
          <button
            disabled={disabled}
            className={clsx(classes.deleteButton, disabled && classes.disabled)}
            onClick={onRemoveCase}
          >
            {t('Remove')}
          </button>
        </div>

        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.caseName,
              fieldRefs,
              element,
              `cases.${caseNumber}.caseName`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <Input
                placeholder={t('Name your case')}
                classNames={classes.input}
                onChange={(e: any) => {
                  field.onChange(e);
                  clearErrors(`cases.${caseNumber}.caseName`);
                }}
                endIcon={endIconOptions(addCaseMessages(t).case_name)}
                maxLength={50}
                value={field.value}
                title={t('Name your case')}
                error={errors?.cases?.[caseNumber]?.caseName?.message as string}
                errorText={errors?.cases?.[caseNumber]?.caseName?.message}
              />
            )}
            name={`cases.${caseNumber}.caseName`}
            control={control}
          />
        </div>

        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.caseBusinessType,
              fieldRefs,
              element,
              `cases.${caseNumber}.caseBusinessType`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <Select
                title={t('Business type')}
                placeholder={t('Select category')}
                endIcon={endIconOptions(addCaseMessages(t).business_types)}
                isSearch
                className={classes.select}
                select={businessTypes?.find(
                  (item: any) => item?.id === field?.value
                )}
                options={internalBusinessTypes}
                defaultValue={caseItem && { name: field.value }}
                setSelect={setSelectBusinessTypes}
                error={errors?.cases?.[caseNumber]?.caseBusinessType?.message}
                onSelect={(item: SelectData) => {
                  field.onChange(item?.id);
                  clearErrors(`cases.${caseNumber}.caseBusinessType`);
                }}
                searchPlaceholder={t('Search')}
                isSolutionForm
                onSearch={(value) => {
                  businessTypes &&
                    setInternalBusinessTypes(
                      businessTypes?.filter((item) =>
                        String(item?.name)
                          ?.toLowerCase()
                          ?.includes(value.toLowerCase())
                      )
                    );
                }}
              />
            )}
            name={`cases.${caseNumber}.caseBusinessType`}
            control={control}
          />
        </div>
      </div>

      <div className={clsx(classes.hint, classes['hint--2'])}>
        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.keyAchievements,
              fieldRefs,
              element,
              `cases.${caseNumber}.keyAchievements`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <Input
                placeholder={t('Key achievement')}
                classNames={classes.input}
                onChange={(e: any) => {
                  field.onChange(e);
                  clearErrors(`cases.${caseNumber}.keyAchievements`);
                }}
                endIcon={endIconOptions(addCaseMessages(t).key_achievements)}
                value={field.value}
                maxLength={50}
                title={t('Key achievement')}
                error={errors?.cases?.[caseNumber]?.keyAchievements?.message}
                errorText={
                  errors?.cases?.[caseNumber]?.keyAchievements?.message
                }
              />
            )}
            name={`cases.${caseNumber}.keyAchievements`}
            control={control}
          />
        </div>

        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.platforms,
              fieldRefs,
              element,
              `cases.${caseNumber}.platforms`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <MultiSelect
                title={t('Platforms')}
                isSolutionForm
                endIcon={endIconOptions(addCaseMessages(t).platforms)}
                additionalClassName={classes.multiselectWrapper}
                options={internalPlatforms}
                selected={multiFieldValues(selectPlatforms, field, i18n, true)}
                onSelect={(id) => {
                  callbacks.onSelectProduct(id, field.onChange);
                  clearErrors(`cases.${caseNumber}.platforms`);
                }}
                onDeleteSelected={(id) => {
                  callbacks.onDeleteSelectedProduct(id, field.onChange);
                  selectPlatforms.length === 1 && field.onChange(null);
                }}
                placeholder=""
                error={errors?.cases?.[
                  caseNumber
                ]?.platforms?.message?.toString()}
              />
            )}
            name={`cases.${caseNumber}.platforms`}
            control={control}
          />
        </div>

        <div
          ref={(element) =>
            writeRef(
              !!errors?.cases?.[caseNumber]?.secondStepDescription,
              fieldRefs,
              element,
              `cases.${caseNumber}.secondStepDescription`
            )
          }
        >
          <Controller
            render={({ field }) => (
              <Textarea
                placeholder={t('Description')}
                value={formattedValue(field.value)}
                onChange={(e: any) => {
                  field.onChange(e);
                  clearErrors(`cases.${caseNumber}.secondStepDescription`);
                }}
                endIcon={endIconOptions(addCaseMessages(t).description)}
                title={t('Description')}
                maxLength={120}
                errorText={
                  errors?.cases?.[caseNumber]?.secondStepDescription?.message
                }
                error={
                  errors?.cases?.[caseNumber]?.secondStepDescription?.message
                }
                errorClassName={
                  !!errors?.cases?.[caseNumber]?.secondStepDescription &&
                  classes.errorTextArea
                }
              />
            )}
            name={`cases.${caseNumber}.secondStepDescription`}
            control={control}
          />
        </div>

        <KPIFormContainer
          caseNumber={caseNumber}
          initialSteps={caseItem?.kpi}
          fieldRefs={fieldRefs}
        />
      </div>
    </div>
  );
};
