import { useUserStore } from 'contexts/UserContext';
import { useCallback, useEffect } from 'react';
import { setOnboardingOptions } from 'tools/onboarding';
import { PersonalFormInputs } from './Personal/Personal.props';
import { useCommonStore } from 'contexts/CommonContext';

export const useAccountContainer = () => {
  const userStore = useUserStore();
  const commonStore = useCommonStore();

  const handleExit = useCallback(() => {
    setOnboardingOptions({ profile: true });
    userStore.enableOnboarding(true);
  }, [userStore.enableOnboarding]);

  useEffect(() => {
    commonStore.loadCompanyProducts();
  }, []);

  const getParams = useCallback(async () => {
    userStore.loadUser();
  }, []);

  const callbacks = {
    onSubmitPersonal: (
      requestData: PersonalFormInputs,
      onSuccess?: () => void,
      onError?: () => void
    ) => {
      const data = {
        full_name: requestData.fullName,
        phone_number: `+${requestData.phone}`,
        user_role_in_company: requestData.jobTitle,
      };

      userStore.updateUser(
        data,
        () => onSuccess?.(),
        () => {
          onError?.();
        }
      );
    },
    onSubmitBusiness: (
      selectedProducts: number[],
      onSuccess?: () => void,
      onError?: () => void
    ) => {
      const data = {
        user_products: selectedProducts,
      };

      userStore.updateUser(
        data,
        () => onSuccess?.(),
        () => {
          onError?.();
        }
      );
    },
  };

  return { handleExit, callbacks, getParams };
};
